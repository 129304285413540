import { Checkbox } from '@components/core'
import clsx from 'clsx'
import { FC } from 'react'
interface DisclaimerProps {
	isChecked: boolean
	toggleCheckbox: () => void
	text: string | JSX.Element
}

export const Disclaimer: FC<DisclaimerProps> = ({ isChecked, toggleCheckbox, text }) => {
	const isCheckedStyle = isChecked ? 'border-yes text-yes' : 'border-no text-no'

	return (
		<div className={clsx(isCheckedStyle, 'flex w-full h-full rounded-2xl border p-5')}>
			<div className="grid grid-cols-12 w-full">
				<span className="grid col-span-10 font-normal text-sm">{text}</span>
				<div className="ml-auto flex items-end col-span-2">
					<Checkbox size="sm" disclaimer isChecked={isChecked} toggleCheckbox={toggleCheckbox} />
				</div>
			</div>
		</div>
	)
}
