import { Collapse } from '@chakra-ui/react'
import { useAppContext } from '@components/app-context'
import { createToast } from '@components/Toasts'
import { Web3Provider } from '@ethersproject/providers'
import useSigninWithWallet from '@hooks/useSigninWithWallet'
import useWindowSize from '@hooks/useWindowSize'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { WalletType } from 'lib/wallet'
import Image from 'next/image'
import QRCode from 'qrcode'
import { FC, useRef } from 'react'
import { useQuery } from 'react-query'
import { ConnectHeader, ConnectWalletStepHeading, Navigation, Retry } from '../ConnectComponents'

const WalletConnect: FC<{
	goBack: () => void
	onClose: () => void
}> = ({ goBack, onClose }) => {
	const canvasRef = useRef<HTMLCanvasElement>()
	const { walletconnectURI, web3Modal } = useAppContext()
	const [, width] = useWindowSize()
	const isMobile = width < 768

	const {
		mutate: signinWithWallet,
		isLoading: isSigninIn,
		isSuccess: isSigninSuccess,
	} = useSigninWithWallet({ onSuccess: onClose })

	const { isSuccess: hasQRCodeLoaded } = useQuery(
		['wc-qr-code', walletconnectURI],
		() => QRCode.toCanvas(canvasRef.current, walletconnectURI, { scale: 6 }),
		{
			enabled: Boolean(width && !isMobile && canvasRef.current && walletconnectURI),
			staleTime: Infinity,
			cacheTime: 0,
			retry: false,
			onError: () => {
				createToast({ body: 'Error in creating the QR code' })
			},
		}
	)

	const {
		data: provider,
		isSuccess: isConnectingSuccess,
		isLoading: isConnecting,
	} = useQuery<WalletConnectProvider>(
		['connect-walletconnect-mobile'],
		() => {
			console.log('CONNECTING TO WALLETCONNECT')
			return web3Modal.connectTo(isMobile ? WalletType.WalletConnectMobile : WalletType.WalletConnect)
		},
		{
			enabled: Boolean(web3Modal && width),
			staleTime: Infinity,
			cacheTime: 0,
			retry: false,
			onSuccess: (_provider) => {
				const web3Provider = new Web3Provider(_provider)
				signinWithWallet(web3Provider)
			},
		}
	)

	return (
		<>
			<ConnectHeader />
			{/* <div className="border border-color-4 divide-y divide-color-4 w-full mt-8 rounded-2xl overflow-y-auto"> */}
			<div className="border border-color-4 divide-y divide-color-4 w-full mt-8 rounded-2xl">
				<div className="flex justify-between items-center p-4 bg-color-2 rounded-t-2xl w-full">
					<h1 className="font-semibold text-color-8 text-lg">WalletConnect</h1>
					<Image src="/images/Wallets/wallet-connect.svg" height={19} width={19} />
				</div>
				{/* <ConnectWalletStepHeading step="authorize" onClick={toggleAuthorise} /> */}
				<ConnectWalletStepHeading step="authorize" isLoading={isConnecting} isSuccess={isConnectingSuccess} />
				{/* --- QR Code --- */}
				<Collapse in={hasQRCodeLoaded && !isConnectingSuccess}>
					<div className="flex flex-col items-center w-full p-4">
						{!isMobile && (
							<>
								<h1 className="font-normal text-color-8 text-lg text-center">
									Scan QR code with a walletConnect compatible wallet
								</h1>
								<div className="flex justify-center my-4">
									<canvas width="350px" height="350px" className="rounded-md" ref={canvasRef} />
								</div>
							</>
						)}
					</div>
				</Collapse>
				<ConnectWalletStepHeading
					step="sign"
					message="Sign the message"
					subHeading={isSigninIn ? 'Open your wallet to signin' : ''}
					isLoading={isSigninIn}
					isSuccess={isSigninSuccess}
				/>
				{/* <p>Open your wallet to signin</p> */}
			</div>
			<div className="flex items-center w-full justify-between pt-8 px-4">
				<Navigation type="back" customName="Back to wallet list" onClick={goBack} />
				{isConnectingSuccess && <Retry onClick={() => signinWithWallet(new Web3Provider(provider))} />}
			</div>
		</>
	)
}

export default WalletConnect
