import { useConnectWalletContext } from '@components/contexts/ConnectWalletProvider'
import { Modal } from '@components/core'
import clsx from 'clsx'
import { tokenAgreementLink } from 'helpers/constants'
import { WalletType } from 'lib/wallet'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC, MouseEventHandler, useMemo, useState } from 'react'
import { ConnectHeader } from './ConnectComponents'
import Coinbase from './connectors/Coinbase'
import Metamask from './connectors/Metamask'
import WalletConnect from './connectors/WalletConnect'

const ChooseWallet: FC<{
	setWallet: (wallet: WalletType) => void
}> = ({ setWallet }) => {
	const router = useRouter()

	const isBrowser = typeof window !== 'undefined'
	const isWalletBrowser = Boolean(isBrowser && window.ethereum)

	const metaMaskDappUrl = isBrowser
		? `https://metamask.app.link/dapp/${(window.location.origin + window.location.pathname)
				.replace('https://', '')
				.replace('http://', '')}`
		: ''

	const wallets = useMemo(
		() => [
			{
				name: 'MetaMask',
				image: '/images/Wallets/metamask.svg',
				onClick: () => {
					if (isWalletBrowser) {
						setWallet(WalletType.Metamask)
					} else {
						router.push(metaMaskDappUrl)
					}
				},
			},
			{
				name: 'WalletConnect',
				image: '/images/Wallets/wallet-connect.svg',
				onClick: () => {
					setWallet(WalletType.WalletConnect)
				},
			},
			{
				name: 'Coinbase Wallet',
				image: '/images/Wallets/coinbase-wallet.svg',
				onClick: async () => {
					setWallet(WalletType.Coinbase)
				},
			},
		],
		[isWalletBrowser, metaMaskDappUrl, router, setWallet]
	)

	return (
		<div className="flex flex-col items-center w-full">
			<ConnectHeader />
			<div className="border border-color-4 divide-y divide-color-4 w-full mt-8 rounded-2xl">
				{wallets.map((wallet, i) => (
					<WalletOption name={wallet.name} image={wallet.image} onClick={wallet.onClick} key={i} />
				))}
			</div>
			<div className="border border-color-4 w-full mt-8 rounded-2xl p-4">
				<h1 className="font-normal text-base text-color-6">By connecting your wallet:</h1>
				<div className="flex items-center mt-2">
					<svg
						width="15"
						height="15"
						viewBox="0 0 15 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="stroke-color-4 mr-2 ml-1"
					>
						<path d="M4.45896 8.2668L6.52887 9.93818L9.68659 5.5957" strokeWidth="1.5" strokeLinecap="round" />
						<rect x="0.84375" y="1.53809" width="12.46" height="12.46" rx="2.14608" strokeWidth="1.5" />
					</svg>
					<h1 className="font-normal text-base text-color-6 tracking-[0.01em]">You agree to our Token Agreement</h1>
				</div>
				<div className="flex items-start mt-2">
					<span className="mr-2 ml-1 mt-1">
						<svg
							width="15"
							height="15"
							viewBox="0 0 15 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="stroke-color-4"
						>
							<path d="M4.45896 8.2668L6.52887 9.93818L9.68659 5.5957" strokeWidth="1.5" strokeLinecap="round" />
							<rect x="0.84375" y="1.53809" width="12.46" height="12.46" rx="2.14608" strokeWidth="1.5" />
						</svg>
					</span>
					<h1 className="font-normal text-base text-color-6 tracking-[0.01em] break-words">
						You agree to our use of cookies to identify your session (authentication cookies).
						<a className="text-gradient-1" href={tokenAgreementLink} target="_blank" rel="noopener noreferrer">
							{' '}
							Learn more.
						</a>
					</h1>
				</div>
			</div>
			<h1 className="font-normal text-base text-color-6 tracking-[0.01em] break-words mt-6 self-start ml-2">
				New to Ethereum?
				<a
					className="text-gradient-1 ml-1"
					href="https://ethereum.org/en/wallets/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Learn more about wallets
				</a>
			</h1>
		</div>
	)
}

/**
 * Modal to connect to a web3 wallet
 * TODO: take open/close from context?
 */
const ConnectWalletModal: FC = () => {
	const [choosenWallet, setChoosenWallet] = useState<WalletType | null>(null)

	const { isOpen, onClose } = useConnectWalletContext()
	const goBack = (): void => setChoosenWallet(null)

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="md" scrollBehavior="outside">
				{!choosenWallet && <ChooseWallet setWallet={setChoosenWallet} />}
				{choosenWallet &&
					(() => {
						switch (choosenWallet) {
							case WalletType.Metamask:
								return <Metamask onClose={onClose} />

							case WalletType.WalletConnect:
							case WalletType.WalletConnectMobile:
								return <WalletConnect onClose={onClose} goBack={goBack} />

							case WalletType.Coinbase:
								return <Coinbase onClose={onClose} />
						}
					})()}
			</Modal>
		</>
	)
}

export default ConnectWalletModal

interface WalletOptionsProps {
	name: string
	image: string
	onClick?: MouseEventHandler
}

const WalletOption: FC<WalletOptionsProps> = ({ name, image, onClick }) => {
	const [hover, setHover] = useState(false)
	return (
		<div
			className="flex items-center justify-between p-4 cursor-pointer"
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={onClick}
		>
			<h1 className={clsx('font-semibold text-lg smooth-hover', hover ? 'text-color-8' : 'text-color-6')}>{name}</h1>
			<Image src={image} width={30} height={30} />
		</div>
	)
}
