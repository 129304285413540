import { Avatar } from '@components/core'
import clsx from 'clsx'
import { FC } from 'react'
import { FollowProfile, User } from '../../../interfaces/interfaces'

interface UserCirclesProps {
	users: User[] | FollowProfile[]
	size?: 'sm' | 'md' | 'lg' | 'xl'
	customSize?: string
	clickable?: boolean
	className?: string
	ring?: boolean
}

export const UserCircles: FC<UserCirclesProps> = ({
	users,
	size = 'md',
	customSize,
	ring = true,
	clickable = true,
	className,
}) => {
	return (
		<div className={`flex flex-wrap justify-center relative items-center ${className}`}>
			{users.map((user) => (
				<Avatar
					key={user.id}
					profilePicUrl={user.profile_pic_url}
					gradient1={user.gradient_1}
					gradient2={user.gradient_2}
					href={clickable && (user.user_name ?? user.wallet_address)}
					size={size}
					customSize={customSize}
					className={clsx('first:ml-0 -ml-[2px]', ring && 'ring-2 ring-color-1')}
				/>
			))}
		</div>
	)
}
