import { FC } from 'react'

// Stroke + Fill necessary
export const BankIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15.37 14.275a.6.6 0 1 0 0-1.2v1.2Zm-13.668-1.2a.6.6 0 1 0 0 1.2v-1.2Zm13.669 0H1.702v1.2h13.669v-1.2Z"
				stroke="none"
			/>
			<path
				d="M4.228 5.382a.6.6 0 0 0-1.2 0h1.2Zm-1.2 8.292a.6.6 0 1 0 1.2 0h-1.2Zm0-8.292v8.292h1.2V5.382h-1.2ZM7.5 5.382a.6.6 0 0 0-1.2 0h1.2Zm-1.2 8.292a.6.6 0 1 0 1.2 0H6.3Zm0-8.292v8.292h1.2V5.382H6.3ZM10.773 5.382a.6.6 0 1 0-1.2 0h1.2Zm-1.2 8.292a.6.6 0 1 0 1.2 0h-1.2Zm0-8.292v8.292h1.2V5.382h-1.2ZM14.046 5.382a.6.6 0 1 0-1.2 0h1.2Zm-1.2 8.292a.6.6 0 1 0 1.2 0h-1.2Zm0-8.292v8.292h1.2V5.382h-1.2Z"
				stroke="none"
			/>
			<path
				d="m1.418 5.008 6.98-3.947a.2.2 0 0 1 .196 0l7.054 3.946c.18.101.109.375-.098.375H1.516c-.205 0-.277-.273-.098-.374Z"
				fill="none"
			/>
		</svg>
	)
}
