import { FC } from 'react'

// Fill
export const HamburgerIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.806885 6.12799C0.806885 5.43763 1.36653 4.87799 2.05688 4.87799H21.9433C22.6337 4.87799 23.1933 5.43763 23.1933 6.12799C23.1933 6.81835 22.6337 7.37799 21.9433 7.37799H2.05688C1.36653 7.37799 0.806885 6.81835 0.806885 6.12799ZM0.806885 12C0.806885 11.3096 1.36653 10.75 2.05688 10.75H21.9433C22.6337 10.75 23.1933 11.3096 23.1933 12C23.1933 12.6903 22.6337 13.25 21.9433 13.25H2.05688C1.36653 13.25 0.806885 12.6903 0.806885 12ZM2.05688 16.622C1.36653 16.622 0.806885 17.1816 0.806885 17.872C0.806885 18.5623 1.36653 19.122 2.05688 19.122H21.9433C22.6337 19.122 23.1933 18.5623 23.1933 17.872C23.1933 17.1816 22.6337 16.622 21.9433 16.622H2.05688Z"
			/>
		</svg>
	)
}
