import { useQuery, UseQueryResult } from 'react-query'
import { getCurrentUser } from '../helpers/queryFunctions'

interface CookieUser {
	publicAddress: string
}

/**
 * @returns {CookieUser} Minimal Data of logged in user
 */
const useCurrentUser = (): UseQueryResult<CookieUser> => {
	return useQuery('current_user', getCurrentUser, {
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		staleTime: 600000,
	})
}

export default useCurrentUser
