import { FC } from 'react'

// Fill
export const DoubleCirclesIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.1854 5.09264C18.9676 5.40883 21.9457 8.57145 21.9607 12.4383C21.9755 16.2599 19.0909 19.4163 15.3747 19.823C17.4989 18.0915 18.8512 15.4504 18.8397 12.4965C18.828 9.48191 17.3986 6.80427 15.1854 5.09264ZM11.9105 5.55522C14.7723 6.56883 16.8273 9.29383 16.8397 12.5042C16.852 15.6677 14.8776 18.3752 12.0893 19.4448C9.22749 18.4312 7.17256 15.7062 7.1601 12.4957C7.14781 9.33231 9.12226 6.62477 11.9105 5.55522ZM8.62512 5.17694C6.50094 6.90852 5.14864 9.54954 5.16011 12.5035C5.17182 15.5181 6.60124 18.1957 8.81443 19.9073C5.03221 19.5911 2.05413 16.4285 2.03912 12.5617C2.02428 8.74004 4.90896 5.58373 8.62512 5.17694ZM11.8832 3.45338C11.0926 3.24109 10.261 3.12935 9.40292 3.13269C4.21129 3.15284 0.0189771 7.37784 0.0391349 12.5695C0.0592927 17.7611 4.28429 21.9534 9.47592 21.9333C10.3933 21.9297 11.2794 21.7949 12.1166 21.5466C12.9072 21.7589 13.7389 21.8706 14.5969 21.8673C19.7885 21.8471 23.9808 17.6221 23.9607 12.4305C23.9405 7.23888 19.7155 3.04656 14.5239 3.06672C13.6066 3.07028 12.7204 3.20513 11.8832 3.45338Z"
			/>
		</svg>
	)
}
