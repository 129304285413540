import { FC } from 'react'

// Stroke
export const DisconnectGnosisSafeIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="none"
			d="M13.836 15.263h-8.23L1.493 8.268l4.115-6.995h8.229M16.832 4.879l2.824 3.389-2.824-3.39ZM16.832 11.658l2.824-3.39-2.824 3.39ZM17.962 8.269H9.15"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
