import { FC } from 'react'

// Stroke
export const CubeIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="none"
			d="m1.26 5.453 6.221 3.11M1 5.194l6.481-3.63 6.482 3.63v6.741l-6.482 3.63L1 11.934v-6.74Zm6.481 9.593V8.564v6.222Zm5.963-9.333-5.963 3.11 5.963-3.11Z"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
