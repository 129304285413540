import useIsomorphicLayoutEffect, { isBrowser } from './useIsomorphicLayoutEffect'

const useLockBodyScroll = (active = false): void => {
	useIsomorphicLayoutEffect((): (() => void) => {
		if (!active || !isBrowser) return

		// Get original body overflow
		const originalStyle: string = window.getComputedStyle(document.body).overflow
		// Prevent scrolling when hook is active
		document.body.style.overflow = 'hidden'

		// Re-enable scrolling when component unmounts
		return () => (document.body.style.overflow = originalStyle)
	}, [active])
}

export default useLockBodyScroll
