import { FC } from 'react'

// Default fill + stroke present
export const MetamaskIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.81 4.142 16.44 11.1l1.733-4.105 7.637-2.853Z"
				fill="#E2761B"
				stroke="#E2761B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m4.18 4.142 9.295 7.024-1.648-4.171L4.18 4.142ZM22.439 20.272l-2.496 3.823 5.34 1.469 1.534-5.207-4.378-.085ZM3.192 20.357l1.525 5.207 5.34-1.47-2.496-3.822-4.37.085Z"
				fill="#E4761B"
				stroke="#E4761B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m9.755 13.813-1.488 2.25 5.301.235-.188-5.696-3.625 3.21ZM20.235 13.812l-3.672-3.277-.123 5.763 5.292-.235-1.497-2.251ZM10.056 24.095l3.183-1.554-2.75-2.146-.433 3.7ZM16.752 22.541l3.192 1.554-.443-3.7-2.75 2.146Z"
				fill="#E4761B"
				stroke="#E4761B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m19.944 24.094-3.192-1.553.254 2.08-.029.877 2.967-1.404ZM10.057 24.094l2.966 1.403-.02-.875.236-2.081-3.182 1.553Z"
				fill="#D7C1B3"
				stroke="#D7C1B3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m13.07 19.02-2.655-.782 1.873-.857.782 1.639ZM16.92 19.02l.782-1.639 1.884.857-2.665.782Z"
				fill="#233447"
				stroke="#233447"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m10.056 24.095.452-3.823-2.947.085 2.495 3.738ZM19.491 20.272l.452 3.823 2.495-3.738-2.947-.085ZM21.732 16.063l-5.292.235.49 2.722.782-1.639 1.883.857 2.137-2.175ZM10.414 18.238l1.883-.857.772 1.639.5-2.722-5.302-.235 2.147 2.175Z"
				fill="#CD6116"
				stroke="#CD6116"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m8.268 16.063 2.222 4.331-.075-2.156-2.147-2.175ZM19.595 18.238l-.094 2.156 2.232-4.331-2.138 2.175ZM13.569 16.298l-.5 2.722.622 3.21.141-4.227-.263-1.705ZM16.44 16.298l-.254 1.695.113 4.238.631-3.211-.49-2.722Z"
				fill="#E4751F"
				stroke="#E4751F"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m16.93 19.02-.63 3.21.451.311 2.75-2.147.094-2.156-2.665.781ZM10.415 18.238l.075 2.156 2.75 2.147.451-.31-.621-3.212-2.655-.781Z"
				fill="#F6851B"
				stroke="#F6851B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m16.977 25.497.029-.875-.236-.207h-3.55l-.216.207.019.875-2.966-1.402 1.035.847 2.1 1.46H16.8l2.109-1.46 1.036-.848-2.966 1.403Z"
				fill="#C0AD9E"
				stroke="#C0AD9E"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m16.752 22.541-.452-.31H13.69l-.452.31-.235 2.081.216-.207h3.55l.236.207-.255-2.08Z"
				fill="#161616"
				stroke="#161616"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m26.205 11.552.8-3.841-1.196-3.57-9.058 6.724 3.484 2.947 4.925 1.441 1.092-1.271-.47-.34.752-.687-.583-.452.753-.574-.5-.377ZM2.994 7.71l.8 3.842-.508.377.753.574-.574.452.753.688-.47.339 1.082 1.271 4.925-1.44 3.484-2.948-9.058-6.723L2.994 7.71Z"
				fill="#763D16"
				stroke="#763D16"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m25.16 15.253-4.925-1.441 1.498 2.25-2.232 4.332 2.938-.038h4.378l-1.657-5.103ZM9.755 13.812l-4.925 1.44-1.638 5.104H7.56l2.928.038-2.222-4.331 1.488-2.251ZM16.44 16.298l.311-5.433 1.432-3.87h-6.356l1.412 3.87.33 5.433.113 1.714.01 4.218h2.607l.02-4.218.122-1.714Z"
				fill="#F6851B"
				stroke="#F6851B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
