declare global {
	interface Window {
		gtag: any
	}
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string): void => {
	if (typeof window !== undefined) {
		window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
			page_path: url,
		})
	}
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
interface GaEvent {
	action: string
	category?: string
	label?: string
	value?: number
}
export const event = ({ action, category, label, value }: GaEvent): void => {
	if (typeof window !== undefined) {
		window.gtag('event', action, {
			event_category: category,
			event_label: label,
			value: value,
		})
	}
}
