import { FC } from 'react'

// Stroke
export const SunIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
			<path
				fill="none"
				d="M9 1.5v2.31m0 10.38v2.31m5.303-12.803L12.67 5.33m-7.34 7.34-1.633 1.633M16.5 9h-2.31M3.81 9H1.5m12.803 5.303L12.67 12.67M5.33 5.33 3.697 3.697M14.068 9A5.068 5.068 0 1 1 3.932 9a5.068 5.068 0 0 1 10.136 0Z"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
		</svg>
	)
}

// Stroke
export const MoonIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
			/>
		</svg>
	)
}
