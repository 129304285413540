import { CSSProperties, FC, useCallback, useRef } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'

const canvasStyles: CSSProperties = {
	position: 'fixed',
	pointerEvents: 'none',
	width: '100%',
	height: '100%',
	top: 0,
	left: 0,
}

interface ConfettiProps {
	setRef: (instance: confetti.CreateTypes) => void
}

export const Confetti: FC<ConfettiProps> = ({ setRef }) => {
	return <ReactCanvasConfetti refConfetti={setRef} style={canvasStyles} />
}

interface ConfettiHook {
	setRef: (instance: confetti.CreateTypes) => void
	fire: () => void
}

export enum Palette {
	Coinvise = 'coinvise',
	Rainbow = 'rainbow',
}

export const useConfetti = (palette: Palette = Palette.Coinvise): ConfettiHook => {
	const colorScheme =
		palette === Palette.Rainbow ? ['#0A549C', '#FAAD14', '#6D99C5', '#FF4D4F'] : ['#2649F5', '#CC92FE', '#7062D4']
	const ref = useRef<confetti.CreateTypes>()

	const setRef = useCallback((instance) => {
		ref.current = instance
	}, [])

	const makeShot = useCallback((particleRatio, opts) => {
		if (!ref.current) return

		ref.current({
			...opts,
			origin: { y: 0.7 },
			particleCount: Math.floor(200 * particleRatio),
			colors: colorScheme,
		})
	}, [])

	const fire = useCallback(() => {
		makeShot(0.25, {
			spread: 26,
			startVelocity: 55,
		})

		makeShot(0.2, {
			spread: 60,
		})

		makeShot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8,
		})

		makeShot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2,
		})

		makeShot(0.1, {
			spread: 120,
			startVelocity: 45,
		})
	}, [makeShot])

	return { setRef, fire }
}
