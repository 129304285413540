import clsx from 'clsx'
import Link from 'next/link'
import { forwardRef, useMemo } from 'react'

interface AvatarProps {
	profilePicUrl?: string
	gradient1: string
	gradient2: string
	href?: string
	className?: string
	size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
	customSize?: string
}

export const Avatar = forwardRef<HTMLElement, AvatarProps>(
	({ profilePicUrl, gradient1, gradient2, href, className, size = 'md', customSize }, ref) => {
		const style = {
			...(profilePicUrl
				? { backgroundImage: `url(${profilePicUrl})` }
				: {
						background: `linear-gradient(${gradient1}, ${gradient2})`,
				  }),
		}

		const width = useMemo(() => {
			if (customSize) return customSize

			let width: string
			switch (size) {
				case 'sm':
					width = 'w-6 h-6'
					break
				case 'md':
					width = 'w-9 h-9'
					break
				case 'lg':
					width = 'w-12 h-12'
					break
				case 'xl':
					width = 'w-24 h-24'
					break
				case '2xl':
					width = 'w-40 h-40'
			}

			return width
		}, [customSize, size])

		const twStyle = clsx(className, width, 'flex shrink-0 rounded-full bg-cover bg-center drop-shadow-xl')
		return (
			<>
				{href ? (
					<Link href={`/${href}`}>
						<span ref={ref} className={clsx(twStyle, 'cursor-pointer')} style={style}></span>
					</Link>
				) : (
					<span ref={ref} className={twStyle} style={style}></span>
				)}
			</>
		)
	}
)

Avatar.displayName = 'Avatar'
