import { Dropdown, DropdownProps } from '@components/core'
import clsx from 'clsx'
import { FC, forwardRef, InputHTMLAttributes, TextareaHTMLAttributes } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string
	placeholder?: string
	className?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({ className, value, ...rest }, ref) => {
	return <input ref={ref} {...rest} value={value ?? ''} className={clsx(className, 'input-base')} />
})

Input.displayName = 'Input'

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	name: string
	placeholder?: string
	className?: string
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ className, value, ...rest }, ref) => {
	return <textarea ref={ref} {...rest} value={value ?? ''} className={clsx(className, 'input-base')} />
})

TextArea.displayName = 'TextArea'

// Same styles as Input/TextArea cmpnt, but uses the logic of Dropdown cmpnt
export const DropdownInput: FC<DropdownProps> = ({ currentOption, options, setOption }) => {
	return (
		<Dropdown
			caretStyle="default"
			border
			outline
			className="text-color-8 m-3 whitespace-nowrap justify-between"
			options={options}
			setOption={setOption}
			currentOption={currentOption}
		/>
	)
}
