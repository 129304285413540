import { Modal as ChakraModal, ModalContent, ModalOverlay, ModalProps } from '@chakra-ui/react'
import useLockBodyScroll from '@hooks/useLockBodyScroll'
import clsx from 'clsx'
import { FC } from 'react'

interface Props extends ModalProps {
	padding?: string
}

export const Modal: FC<Props> = ({ isOpen, onClose, size = 'xl', padding = 'p-10', children, ...rest }) => {
	useLockBodyScroll(isOpen)

	return (
		<ChakraModal
			isOpen={isOpen}
			onClose={onClose}
			size={size}
			isCentered
			motionPreset="slideInBottom"
			scrollBehavior="inside"
			preserveScrollBarGap
			blockScrollOnMount
			{...rest}
		>
			<ModalOverlay className="dark:bg-[#4A485F] dark:bg-opacity-30" />
			<ModalContent
				className={clsx('bg-color-0 dark:bg-color-0 flex flex-col items-start', padding)}
				borderRadius="35px"
			>
				{children}
			</ModalContent>
		</ChakraModal>
	)
}
