import { AirdropEligibility } from '@hooks/query/useAirdropEligibility'
import { UseQueryOptions } from 'react-query'
import { AirdropUniqueLink, CampaignWithDetails, RewardWithDetails } from '../interfaces/interfaces'

export const getMilliFromSeconds = (seconds: number): number => seconds * 1000
export const getMilliFromMinutes = (minutes: number): number => minutes * 60000

export const campaignSettings: UseQueryOptions<CampaignWithDetails> = {
	staleTime: getMilliFromMinutes(1),
	cacheTime: getMilliFromMinutes(10),
	refetchOnMount: true,
	refetchOnWindowFocus: false,
	retry: false,
}

export const airdropEligibilitySettings: UseQueryOptions<AirdropEligibility> = {
	staleTime: getMilliFromMinutes(1),
	refetchOnMount: true,
	refetchOnWindowFocus: false,
	retry: false,
}

export const airdropSettings: UseQueryOptions<AirdropUniqueLink> = {
	staleTime: getMilliFromMinutes(1),
	cacheTime: getMilliFromMinutes(10),
	refetchOnMount: true,
	refetchOnWindowFocus: false,
	retry: false,
}

export const rewardSettings: UseQueryOptions<RewardWithDetails> = {
	staleTime: getMilliFromMinutes(1),
	cacheTime: getMilliFromMinutes(10),
	refetchOnMount: true,
	refetchOnWindowFocus: false,
}

export const rewardsSettings: UseQueryOptions<RewardWithDetails[]> = {
	staleTime: getMilliFromMinutes(5),
	cacheTime: getMilliFromMinutes(10),
	refetchOnWindowFocus: false,
}

export const leaderboardConvoSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(10),
	cacheTime: getMilliFromMinutes(30),
	refetchOnWindowFocus: false,
}

export const leaderboardSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(2),
	cacheTime: getMilliFromMinutes(10),
	refetchOnWindowFocus: false,
}

export const reputationScoreSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(2),
	cacheTime: getMilliFromMinutes(5),
	refetchOnWindowFocus: true,
}

export const transactionsSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(2),
	cacheTime: getMilliFromMinutes(5),
}
export const topSupporterSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(2),
	cacheTime: getMilliFromMinutes(5),

	// Increased retrying as covalent api throws error multiple times, but return the data after some time
	retry: 6,
}

export const supporterListSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(2),
	cacheTime: getMilliFromMinutes(5),
}

export const vestDataSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(1),
	cacheTime: getMilliFromMinutes(1),
}

export const twitterFollowersSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(1),
	cacheTime: getMilliFromMinutes(10),
}

export const pastAirdropsSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(5),
	cacheTime: getMilliFromMinutes(5),
}

export const questDataSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(2),
	cacheTime: getMilliFromMinutes(5),
	refetchOnWindowFocus: false,
}

export const poapEventDetailsSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(1),
	cacheTime: getMilliFromMinutes(1),
	retry: false,
}

export const pendingAirdropsSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(1),
	cacheTime: getMilliFromMinutes(1),
}

export const membershipByAddressSettings: UseQueryOptions<any> = {
	staleTime: getMilliFromMinutes(1),
	cacheTime: getMilliFromMinutes(1),
	retry: false,
}
