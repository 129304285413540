import { useDisclosure } from '@chakra-ui/react'
import { WalletType } from 'lib/wallet'
import { createContext, FC, useContext, useMemo, useState } from 'react'

interface ConnectWalletState {
	isOpen: boolean
	wallet?: WalletType
}

interface ConnectWalletContext extends ConnectWalletState {
	onClose: () => void
	onOpen: () => void
}

const ConnectWalletContext = createContext<ConnectWalletContext | undefined>(undefined)

export const useConnectWalletContext = (): ConnectWalletContext => {
	const context = useContext(ConnectWalletContext)
	if (!context) {
		throw new Error('useConnectWalletContext must be used within a ConnectWalletProvider')
	}

	return context
}

export const ConnectWalletProvider: FC = ({ children }) => {
	const { isOpen, onClose, onOpen } = useDisclosure()
	const [wallet, setWallet] = useState<WalletType>(null)

	const state = useMemo<ConnectWalletContext>(() => {
		return {
			isOpen,
			onClose: () => {
				onClose()
				setWallet(null)
			},
			onOpen,
			wallet,
		}
	}, [isOpen, onClose, onOpen, wallet])

	return <ConnectWalletContext.Provider value={state}>{children}</ConnectWalletContext.Provider>
}
