import { BlinkingDots } from '@components/core'
import Image from 'next/image'
import { FC } from 'react'

export const CheckAnimation: FC<{ className: string; isSuccess: boolean; isLoading: boolean }> = ({
	className,
	isSuccess = false,
	isLoading = true,
}) => {
	return (
		<span className={className}>
			{isLoading ? (
				<BlinkingDots />
			) : isSuccess ? (
				<Image src="/images/check.svg" className="flex my-auto" width={18} height={18} />
			) : (
				<Image src="/images/CrossRounded.svg" className="flex" width={18} height={18} />
			)}
		</span>
	)
}
