import clsx from 'clsx'
import { FC } from 'react'

// Fill
export const DiscordIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
			<path d="M18.1747 1.34048C16.8191 0.706207 15.3851 0.255411 13.9104 0C13.7264 0.331762 13.5125 0.779278 13.3637 1.13378C11.7973 0.895035 10.2037 0.895035 8.63735 1.13378C8.47309 0.746397 8.28853 0.367946 8.08442 0C6.60846 0.255832 5.17329 0.70769 3.81699 1.34358C1.11846 5.42187 0.386723 9.39888 0.752591 13.319C2.32785 14.5084 4.0977 15.4152 5.98326 15.999C6.40692 15.4159 6.78164 14.7989 7.1036 14.1541C6.49122 13.9207 5.90081 13.6333 5.33938 13.2953C5.48717 13.1857 5.63186 13.071 5.77139 12.9532C9.17272 14.5438 12.8676 14.5438 16.2286 12.9532C16.3702 13.071 16.5149 13.1857 16.6606 13.2953C16.1015 13.6322 15.5093 13.9216 14.8933 14.1552C15.2147 14.8002 15.5895 15.4173 16.0136 16C17.9007 15.4166 19.6717 14.5091 21.2474 13.318C21.6763 8.77463 20.5146 4.8338 18.1747 1.34048ZM7.56662 10.9078C6.5455 10.9078 5.70834 9.95491 5.70834 8.79426C5.70834 7.63362 6.52793 6.67864 7.56662 6.67864C8.60532 6.67864 9.44247 7.63155 9.4249 8.79426C9.42593 9.95491 8.60532 10.9078 7.56662 10.9078ZM14.4334 10.9078C13.4122 10.9078 12.5751 9.95491 12.5751 8.79426C12.5751 7.63362 13.3947 6.67864 14.4334 6.67864C15.4721 6.67864 16.3092 7.63155 16.2916 8.79426C16.2916 9.95491 15.4721 10.9078 14.4334 10.9078Z" />
		</svg>
	)
}

// Fill
export const TwitterIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path d="M 24 4.300781 C 23.101563 4.699219 22.199219 5 21.199219 5.101563 C 22.199219 4.5 23 3.5 23.398438 2.398438 C 22.398438 3 21.398438 3.398438 20.300781 3.601563 C 19.300781 2.601563 18 2 16.601563 2 C 13.898438 2 11.699219 4.199219 11.699219 6.898438 C 11.699219 7.300781 11.699219 7.699219 11.800781 8 C 7.699219 7.800781 4.101563 5.898438 1.699219 2.898438 C 1.199219 3.601563 1 4.5 1 5.398438 C 1 7.101563 1.898438 8.601563 3.199219 9.5 C 2.398438 9.398438 1.601563 9.199219 1 8.898438 C 1 8.898438 1 8.898438 1 9 C 1 11.398438 2.699219 13.398438 4.898438 13.800781 C 4.5 13.898438 4.101563 14 3.601563 14 C 3.300781 14 3 14 2.699219 13.898438 C 3.300781 15.898438 5.101563 17.300781 7.300781 17.300781 C 5.601563 18.601563 3.5 19.398438 1.199219 19.398438 C 0.800781 19.398438 0.398438 19.398438 0 19.300781 C 2.199219 20.699219 4.800781 21.5 7.5 21.5 C 16.601563 21.5 21.5 14 21.5 7.5 C 21.5 7.300781 21.5 7.101563 21.5 6.898438 C 22.5 6.199219 23.300781 5.300781 24 4.300781" />
		</svg>
	)
}

// Fill
export const TwitterCircleIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg
			className={className}
			width="23"
			height="22"
			viewBox="0 0 23 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M11.5 0C5.42546 0 0.5 4.92546 0.5 11C0.5 17.0745 5.42546 22 11.5 22C17.5745 22 22.5 17.0745 22.5 11C22.5 4.92546 17.5745 0 11.5 0ZM16.7864 8.29175C16.7937 8.40713 16.7937 8.52744 16.7937 8.64532C16.7937 12.2498 14.0487 16.4018 9.03238 16.4018C7.48549 16.4018 6.05158 15.9524 4.84353 15.179C5.06451 15.2036 5.27567 15.2134 5.50158 15.2134C6.77836 15.2134 7.95199 14.7813 8.88748 14.0495C7.68931 14.025 6.68259 13.2393 6.33885 12.1589C6.75871 12.2203 7.13681 12.2203 7.56899 12.1098C6.95201 11.9845 6.39747 11.6494 5.99956 11.1615C5.60165 10.6736 5.3849 10.063 5.38616 9.4335V9.39909C5.7471 9.6029 6.17189 9.72811 6.61629 9.74532C6.24268 9.49632 5.9363 9.15899 5.72427 8.76324C5.51228 8.36748 5.40121 7.92552 5.40092 7.47658C5.40092 6.96828 5.5335 6.50423 5.77164 6.10158C6.45648 6.94457 7.311 7.63405 8.27969 8.12519C9.24842 8.61632 10.3097 8.89812 11.3944 8.95224C11.0089 7.09842 12.3937 5.59822 14.0585 5.59822C14.8442 5.59822 15.5513 5.92724 16.0498 6.45759C16.6661 6.34221 17.2553 6.1114 17.7808 5.80199C17.577 6.43304 17.1498 6.96584 16.5826 7.30224C17.1326 7.24328 17.6629 7.09109 18.154 6.87744C17.7832 7.42256 17.3192 7.90626 16.7864 8.29175Z" />
		</svg>
	)
}

// Fill
export const MirrorIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.806396 6.69355C0.806396 3.27295 3.57934 0.5 6.99994 0.5C10.4206 0.5 13.1935 3.27295 13.1935 6.69355V15.5589C13.1935 16.0786 12.7721 16.5 12.2523 16.5H1.74758C1.22778 16.5 0.806396 16.0786 0.806396 15.5589V6.69355Z" />
		</svg>
	)
}

// Fill
export const GitHubIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path d="M12 0C5.374 0 0 5.373 0 12C0 17.302 3.438 21.8 8.207 23.387C8.806 23.498 9 23.126 9 22.81V20.576C5.662 21.302 4.967 19.16 4.967 19.16C4.421 17.773 3.634 17.404 3.634 17.404C2.545 16.659 3.717 16.675 3.717 16.675C4.922 16.759 5.556 17.912 5.556 17.912C6.626 19.746 8.363 19.216 9.048 18.909C9.155 18.134 9.466 17.604 9.81 17.305C7.145 17 4.343 15.971 4.343 11.374C4.343 10.063 4.812 8.993 5.579 8.153C5.455 7.85 5.044 6.629 5.696 4.977C5.696 4.977 6.704 4.655 8.997 6.207C9.954 5.941 10.98 5.808 12 5.803C13.02 5.808 14.047 5.941 15.006 6.207C17.297 4.655 18.303 4.977 18.303 4.977C18.956 6.63 18.545 7.851 18.421 8.153C19.191 8.993 19.656 10.064 19.656 11.374C19.656 15.983 16.849 16.998 14.177 17.295C14.607 17.667 15 18.397 15 19.517V22.81C15 23.129 15.192 23.504 15.801 23.386C20.566 21.797 24 17.3 24 12C24 5.373 18.627 0 12 0Z" />
		</svg>
	)
}

// Fill
export const SnapshotIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.916.476a.833.833 0 0 1 .584.795v4.167h3.333a.833.833 0 0 1 .683 1.31l-5.833 8.334a.833.833 0 0 1-1.517-.477v-4.167H.833a.833.833 0 0 1-.683-1.31L5.983.793a.833.833 0 0 1 .933-.317Z"
		/>
	</svg>
)

export const RedditIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M17.3 7.739c0-1.13-.895-2.048-1.994-2.048a1.94 1.94 0 0 0-1.28.492l-.035.022c-1.296-.852-3.015-1.396-4.916-1.47l1.135-3.316 2.832.696c.002.92.73 1.668 1.627 1.668s1.628-.75 1.628-1.672c0-.923-.73-1.673-1.628-1.673-.682 0-1.264.434-1.506 1.045L9.807.659 8.414 4.727c-1.983.039-3.78.59-5.124 1.47a1.94 1.94 0 0 0-1.298-.506C.893 5.69 0 6.609 0 7.739c0 .698.35 1.343.919 1.72a3.693 3.693 0 0 0-.057.62c0 2.955 3.487 5.36 7.772 5.36 4.287 0 7.773-2.405 7.773-5.36a3.698 3.698 0 0 0-.052-.603 2.061 2.061 0 0 0 .945-1.737Zm-6.023 2.651a1.195 1.195 0 0 1-.865-.369 1.26 1.26 0 0 1-.359-.89c0-.248.072-.491.207-.698.135-.207.326-.368.55-.464a1.194 1.194 0 0 1 1.334.273 1.29 1.29 0 0 1 .265 1.371c-.093.23-.25.427-.451.565a1.202 1.202 0 0 1-.68.212Zm.244 2.146c-.037.038-.915.957-2.9.957-1.997 0-2.795-.931-2.829-.971a.328.328 0 0 1 .035-.454.31.31 0 0 1 .44.033c.018.021.684.75 2.354.75 1.697 0 2.442-.755 2.45-.762a.309.309 0 0 1 .437-.002.326.326 0 0 1 .013.449Zm-6.58-3.404a1.289 1.289 0 0 1 .351-.904 1.223 1.223 0 0 1 .874-.376 1.196 1.196 0 0 1 .874.377 1.262 1.262 0 0 1 .35.904c-.006.33-.138.644-.367.875-.229.23-.537.36-.858.36-.321 0-.63-.13-.858-.36a1.275 1.275 0 0 1-.366-.876Z" />
	</svg>
)

// Fill
export const InstagramIcon: FC<{ className: string; gradient?: boolean }> = ({ className, gradient }) => (
	<svg
		className={clsx(className, gradient && 'fill-[url(#instagram_gradient)]')}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 15 16"
	>
		<path d="M4.54.438C2.04.438 0 2.478 0 4.978v5.921c0 2.5 2.04 4.54 4.54 4.54h5.92c2.5 0 4.54-2.04 4.54-4.54V4.978c0-2.5-2.04-4.54-4.54-4.54H4.54Zm0 1.185h5.92a3.347 3.347 0 0 1 3.356 3.355v5.921a3.347 3.347 0 0 1-3.356 3.355H4.54A3.347 3.347 0 0 1 1.183 10.9V4.978A3.346 3.346 0 0 1 4.54 1.623Zm6.907 1.579a.79.79 0 1 0 0 1.578.79.79 0 0 0 0-1.578Zm-3.947.79a3.957 3.957 0 0 0-3.947 3.946A3.956 3.956 0 0 0 7.5 11.886a3.956 3.956 0 0 0 3.947-3.948A3.956 3.956 0 0 0 7.5 3.991Zm0 1.183a2.754 2.754 0 0 1 2.763 2.763A2.754 2.754 0 0 1 7.5 10.702a2.754 2.754 0 0 1-2.763-2.764A2.755 2.755 0 0 1 7.5 5.175Z" />
		<defs>
			<linearGradient
				id="instagram_gradient"
				x1="-.389"
				y1=".047"
				x2="14.528"
				y2="15.409"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FF64AE" />
				<stop offset="1" stopColor="#553CEF" />
			</linearGradient>
		</defs>
	</svg>
)
