import clsx from 'clsx'
import { FC } from 'react'

// Fill
export const WidgetsIcon: FC<{ className?: string; gradient?: boolean }> = ({ className, gradient }) => (
	<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path
			className={clsx(className, gradient && 'fill-[url(#widgets_gradient)]')}
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13 26.5C20.1797 26.5 26 20.6797 26 13.5C26 6.3203 20.1797 0.5 13 0.5C5.8203 0.5 0 6.3203 0 13.5C0 20.6797 5.8203 26.5 13 26.5ZM5.78479 8.28418C5.78479 7.17961 6.68022 6.28418 7.78479 6.28418H9.69979C10.8044 6.28418 11.6998 7.17961 11.6998 8.28418V10.1992C11.6998 11.3037 10.8044 12.1992 9.69979 12.1992H7.78479C6.68022 12.1992 5.78479 11.3037 5.78479 10.1992V8.28418ZM5.78479 17.3192C5.78479 16.2146 6.68022 15.3192 7.78479 15.3192H9.69979C10.8044 15.3192 11.6998 16.2146 11.6998 17.3192V19.2342C11.6998 20.3387 10.8044 21.2342 9.69979 21.2342H7.78479C6.68022 21.2342 5.78479 20.3387 5.78479 19.2342V17.3192ZM16.8198 6.28418C15.7152 6.28418 14.8198 7.17961 14.8198 8.28418V10.1992C14.8198 11.3037 15.7152 12.1992 16.8198 12.1992H18.7348C19.8394 12.1992 20.7348 11.3037 20.7348 10.1992V8.28418C20.7348 7.17961 19.8394 6.28418 18.7348 6.28418H16.8198ZM14.8198 17.3192C14.8198 16.2146 15.7152 15.3192 16.8198 15.3192H18.7348C19.8394 15.3192 20.7348 16.2146 20.7348 17.3192V19.2342C20.7348 20.3387 19.8394 21.2342 18.7348 21.2342H16.8198C15.7152 21.2342 14.8198 20.3387 14.8198 19.2342V17.3192Z"
		/>
		<defs>
			<linearGradient
				id="widgets_gradient"
				x1="26.6075"
				y1="-3.54443"
				x2="-1.71761"
				y2="-1.99419"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#E8B05D" />
				<stop offset="28%" stopColor="#ED6F4D" />
				<stop offset="81%" stopColor="#7765CB" />
				<stop offset="100%" stopColor="#4F81E2" />
			</linearGradient>
		</defs>
	</svg>
)
