import { LeftCaretIcon, RightCaretIcon } from '@components/icons'
import clsx from 'clsx'
import { FC } from 'react'

export const Pagination: FC<{ handleNext: () => void; handlePrev: () => void; index: number; pages: number }> = ({
	handleNext,
	handlePrev,
	index,
	pages,
}) => {
	return (
		<div className="flex flex-row w-full justify-center items-center gap-2">
			{/* Left Caret */}
			<button
				onClick={handlePrev}
				className="mx-2 shrink-0 w-8 h-8 rounded-full border border-color-5 flex justify-center items-center"
			>
				<LeftCaretIcon className="stroke-color-6 w-3 h-3" />
			</button>
			{/* Use a fraction instead of dots if pages exceed 15 */}
			{pages > 15 ? (
				<span className="text-color-7">
					{index} / {pages}
				</span>
			) : (
				Array(pages)
					.fill(0)
					.map((v, i) => (
						<div key={i} className={clsx('shrink-0 rounded-full w-1 h-1 bg-color-3', i === index && 'bg-color-6')} />
					))
			)}
			{/* Right Caret */}
			<button
				onClick={handleNext}
				className="mx-2 shrink-0 w-8 h-8 rounded-full border border-color-5 flex justify-center items-center"
			>
				<RightCaretIcon className="stroke-color-6 w-3 h-3" />
			</button>
		</div>
	)
}
