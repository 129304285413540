import { useRef } from 'react'

/**
 * @see {@link https://usehooks-ts.com/react-hook/use-is-first-render usehooks-ts.com}
 * @returns `true` if the component is mounted for the first time, else `false`
 */
const useIsFirstRender = (): boolean => {
	const isFirst = useRef(true)

	if (isFirst.current) {
		isFirst.current = false

		return true
	}

	return isFirst.current
}

export default useIsFirstRender
