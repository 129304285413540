import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react'
import { useTheme } from 'next-themes'
import { FC } from 'react'

export const Tooltip: FC<TooltipProps> = ({ children, label, placement, textColor, bgColor, ...rest }) => {
	const { theme } = useTheme()
	return (
		<ChakraTooltip
			label={label}
			placement={placement ?? 'right'}
			color={textColor ? textColor : theme === 'light' ? '#fff' : '#605e8a'}
			bg={bgColor ? bgColor : theme === 'light' ? '#414141' : '#161527'}
			{...rest}
		>
			<span>{children}</span>
		</ChakraTooltip>
	)
}
