import { Web3Provider } from '@ethersproject/providers'
import Safe from '@gnosis.pm/safe-core-sdk'
import SafeServiceClient from '@gnosis.pm/safe-service-client'
import { createContext, useContext } from 'react'
import Web3Modal from 'web3modal'

export interface IAppContext {
	web3Modal?: Web3Modal | null
	walletProvider?: Web3Provider | null
	// primaryProvider?: Web3Provider | null
	// gnosisProvider?: Web3Provider | null
	maticProvider?: Web3Provider | null
	biconomy?: any
	address?: string
	selectedMenuKey?: string
	chainId: number
	walletconnectURI: string
	// gnosisAddress?: string
	safeSdk?: Safe
	safeService?: SafeServiceClient
}

const AppContext = createContext<IAppContext | undefined>(undefined)
export const useAppContext = (): IAppContext => {
	const context = useContext(AppContext)
	if (!context) {
		throw new Error('useAppContext must be used within a AppProvider')
	}

	return context
}

export default AppContext
