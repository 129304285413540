import { FC } from 'react'

// Fill
export const NotificationBellIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} width="22" height="22" viewBox="0 0 22 25" xmlns="http://www.w3.org/2000/svg">
			<path d="M11 24.5a3.436 3.436 0 0 0 3.25-2.306h-6.5A3.436 3.436 0 0 0 11 24.5Zm8.072-9.487V9.725c0-3.71-2.52-6.835-5.933-7.774A2.295 2.295 0 0 0 11 .5c-.975 0-1.801.6-2.139 1.45-3.413.942-5.933 4.065-5.933 7.775v5.288L.96 16.982a1.148 1.148 0 0 0-.338.815v1.153a1.153 1.153 0 0 0 1.153 1.153h18.45a1.153 1.153 0 0 0 1.153-1.153v-1.153a1.15 1.15 0 0 0-.338-.816l-1.968-1.968Z" />
		</svg>
	)
}

// Fill
// Unread dot remains red, but fill color of bell can be modified
export const NotificationBellUnreadIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#a)">
				<path d="M13.05 23.866a3.437 3.437 0 0 1-1.993.634 3.436 3.436 0 0 1-3.25-2.306h6.5a3.436 3.436 0 0 1-1.257 1.672ZM19.129 14.835v.178l1.968 1.969a1.147 1.147 0 0 1 .338.815v1.153a1.153 1.153 0 0 1-1.153 1.153H1.832A1.153 1.153 0 0 1 .68 18.95v-1.153a1.15 1.15 0 0 1 .338-.816l1.968-1.968V9.725c0-3.71 2.52-6.833 5.933-7.774A2.295 2.295 0 0 1 11.058.5c.975 0 1.8.6 2.138 1.45A8.089 8.089 0 0 1 16.735 4a5.599 5.599 0 0 0 2.394 10.835Z" />
				<circle cx="19.128" cy="9.257" r="4.193" fill="#FF806F" />
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>
	)
}

// Fill (gradient ring is always there)
export const NotificationBellGradientRingIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg
			className={className}
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.6875" y="0.6875" width="20.625" height="20.625" rx="10.3125" fill="none" />
			<path d="M11 16.5646C11.3312 16.565 11.6542 16.4622 11.9243 16.2706C12.1943 16.0789 12.3979 15.8079 12.5068 15.4951H9.49315C9.60206 15.8079 9.80569 16.0789 10.0757 16.2706C10.3458 16.4622 10.6688 16.565 11 16.5646ZM14.7431 12.1653V9.71308C14.7431 7.99288 13.5747 6.54378 11.9919 6.10798C11.8352 5.71336 11.4524 5.4353 11 5.4353C10.5476 5.4353 10.1648 5.71336 10.0081 6.10798C8.42531 6.54432 7.25694 7.99288 7.25694 9.71308V12.1653L6.34417 13.0781C6.29442 13.1277 6.25497 13.1866 6.22808 13.2515C6.20119 13.3163 6.1874 13.3859 6.1875 13.4561V13.9909C6.1875 14.1327 6.24384 14.2687 6.34412 14.369C6.4444 14.4692 6.58041 14.5256 6.72222 14.5256H15.2778C15.4196 14.5256 15.5556 14.4692 15.6559 14.369C15.7562 14.2687 15.8125 14.1327 15.8125 13.9909V13.4561C15.8126 13.3859 15.7988 13.3163 15.7719 13.2515C15.745 13.1866 15.7056 13.1277 15.6558 13.0781L14.7431 12.1653Z" />
			<rect
				x="0.6875"
				y="0.6875"
				width="20.625"
				height="20.625"
				rx="10.3125"
				stroke="url(#g8)"
				fill="none"
				strokeWidth="1.375"
			/>
			<defs>
				<linearGradient id="g8" x1="22.514" y1="-3.42221" x2="-1.45336" y2="-2.11047" gradientUnits="userSpaceOnUse">
					<stop stopColor="#E8B05D" />
					<stop offset="0.28125" stopColor="#ED6F4D" />
					<stop offset="0.807292" stopColor="#7765CB" />
					<stop offset="1" stopColor="#4F81E2" />
				</linearGradient>
			</defs>
		</svg>
	)
}
