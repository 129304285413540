import { FC, useEffect } from 'react'
import { toast } from 'react-toastify'
import useCurrentUser from '../hooks/useCurrentUser'
import { useAppContext } from './app-context'
import { updateToast } from './Toasts'

const key = 'checkUser'

const CheckUser: FC = () => {
	const { walletProvider } = useAppContext()
	const { data: user } = useCurrentUser()

	useEffect(() => {
		;(async () => {
			if (!walletProvider || !user) return

			const activeWallet = await walletProvider.getSigner().getAddress()
			const signedinWallet = user.publicAddress
			if (activeWallet !== signedinWallet) {
				updateToast({
					body: 'Current wallet not same as signed in wallet',
					toastId: key,
				})
			} else {
				toast.dismiss(key)
			}
		})()
	}, [walletProvider, user])

	return null
}

export default CheckUser
