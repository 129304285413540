import { BlinkingDots } from '@components/core'
import clsx from 'clsx'
import Image from 'next/image'
import { FC, HTMLProps, MouseEventHandler, useState } from 'react'

export const ConnectHeader: FC = () => {
	return (
		<div className="flex flex-col items-center w-full">
			<svg className="fill-color-8 w-10 h-10" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12.7738 0.700195H2.27383C1.11603 0.700195 0.173828 1.6424 0.173828 2.8002V11.2002C0.173828 12.358 1.11603 13.3002 2.27383 13.3002H12.7738C13.5459 13.3002 14.1738 12.6723 14.1738 11.9002V2.1002C14.1738 1.3281 13.5459 0.700195 12.7738 0.700195ZM2.27383 11.9002C1.88743 11.9002 1.57383 11.5859 1.57383 11.2002V2.8002C1.57383 2.4145 1.88743 2.1002 2.27383 2.1002H12.7738V4.2002H8.57383C7.80173 4.2002 7.17383 4.8281 7.17383 5.6002V8.4002C7.17383 9.1723 7.80173 9.8002 8.57383 9.8002H12.7745V11.9002H2.27383ZM12.7738 5.6002V8.4002H8.57383V5.6002H12.7738Z" />
			</svg>

			<h1 className="text-2xl font-semibold text-color-8 mt-2">Sign in with your crypto wallet </h1>
		</div>
	)
}

interface ConnectWalletStepHeadingProps {
	step: 'authorize' | 'sign'
	isSuccess?: boolean
	isLoading?: boolean
	message?: string
	subHeading?: string
}

/**
 * Header for step in the connect modal
 * @returns Steps for connecting Wallet 1. Auth and 2. Sign
 */
export const ConnectWalletStepHeading: FC<ConnectWalletStepHeadingProps> = ({
	isLoading,
	isSuccess,
	step,
	message,
	subHeading,
}) => {
	switch (step) {
		case 'authorize':
			return (
				<div className="flex items-center justify-between p-4">
					<div className="flex">
						<h1 className="text-color-6 text-lg rounded-full w-8 h-8 bg-color-3 flex items-center justify-center">1</h1>
						<h1 className="font-semibold text-lg text-color-8 ml-2">Authorise your wallet</h1>
					</div>
					<div className="flex items-center">
						{isSuccess ? (
							<Image src="/images/check.svg" className="flex" width={18} height={18} />
						) : isLoading ? (
							<Image src="/images/spinner.svg" className="flex animate-spin" width={24} height={24} />
						) : (
							<BlinkingDots />
						)}
					</div>
				</div>
			)
		case 'sign':
			return (
				<div className="flex items-center justify-between p-4 ">
					<div className="flex">
						<h1 className="text-color-6 text-lg rounded-full w-8 h-8 bg-color-3 flex items-center justify-center">2</h1>
						<div className="ml-2">
							<h1 className="font-semibold text-lg text-color-8">{message ?? 'Sign in to Coinvise'}</h1>
							{subHeading && <p className="text-color-7 text-sm">{subHeading}</p>}
						</div>
					</div>
					<div className="flex items-center">
						{isSuccess ? (
							<Image src="/images/check.svg" className="flex" width={18} height={18} />
						) : isLoading ? (
							<Image src="/images/spinner.svg" className="flex animate-spin" width={24} height={24} />
						) : (
							<BlinkingDots />
						)}
					</div>
				</div>
			)
	}
}

interface RetryProps {
	onClick?: MouseEventHandler
}

// TODO: Move to global container later
export const Retry: FC<RetryProps> = ({ onClick }) => {
	const [hover, setHover] = useState(false)

	return (
		<div
			onClick={onClick}
			className="flex items-center cursor-pointer"
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<h1 className={clsx('font-normal text-lg smooth-hover', hover ? 'text-color-8' : 'text-color-6')}>Retry</h1>
			<svg
				width="13"
				height="15"
				viewBox="0 0 13 15"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={clsx('ml-2 smooth-hover', hover ? 'stroke-color-8' : 'stroke-color-6')}
			>
				<path d="M7.16602 1.19416L8.97958 3.00773L7.16602 4.82129" strokeWidth="2" strokeLinecap="round" />
				<path d="M8.96094 4.00781C9.51322 4.00781 9.96094 3.5601 9.96094 3.00781C9.96094 2.45553 9.51322 2.00781 8.96094 2.00781L8.96094 4.00781ZM6.55078 2.00781C5.9985 2.00781 5.55078 2.45553 5.55078 3.00781C5.55078 3.5601 5.9985 4.00781 6.55078 4.00781L6.55078 2.00781ZM8.96094 2.00781L6.55078 2.00781L6.55078 4.00781L8.96094 4.00781L8.96094 2.00781Z" />
				<path
					d="M11.5508 8.00781C11.5508 8.99672 11.2575 9.96342 10.7081 10.7857C10.1587 11.6079 9.37783 12.2488 8.4642 12.6272C7.55057 13.0056 6.54523 13.1047 5.57533 12.9117C4.60542 12.7188 3.71451 12.2426 3.01525 11.5433C2.31598 10.8441 1.83978 9.95317 1.64685 8.98326C1.45393 8.01336 1.55295 7.00803 1.93138 6.09439C2.30982 5.18076 2.95068 4.39987 3.77293 3.85046C4.59518 3.30106 5.56187 3.00781 6.55078 3.00781"
					strokeWidth="2"
					strokeLinecap="round"
				/>
			</svg>
		</div>
	)
}

interface NavigationProps extends HTMLProps<HTMLButtonElement> {
	type: 'back' | 'next'
	customName?: string
}

export const Navigation: FC<NavigationProps> = ({ type, onClick, className, customName }) => {
	const [hover, setHover] = useState(false)

	switch (type) {
		case 'back':
			return (
				<button
					className={clsx(
						'text-lg font-normal flex items-center smooth-hover',
						hover ? 'text-color-8' : 'text-color-6',
						className
					)}
					onClick={onClick}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					type="button"
				>
					<svg
						width="9"
						height="15"
						viewBox="0 0 9 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={clsx('smooth-hover mr-2', hover ? 'stroke-color-8' : 'stroke-color-6')}
					>
						<path d="M7.55078 13.1011L1.55078 7.10107L7.55078 1.10107" strokeWidth="2" strokeLinecap="round" />
					</svg>
					{customName ?? 'Back'}
				</button>
			)
		case 'next':
			return (
				<button
					className={clsx(
						'text-lg font-normal flex items-center smooth-hover',
						hover ? 'text-color-8' : 'text-color-6',
						className
					)}
					onClick={onClick}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					type="button"
				>
					{customName ?? 'Next'}
					<svg
						width="9"
						height="15"
						viewBox="0 0 9 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={clsx('smooth-hover rotate-180 ml-2', hover ? 'stroke-color-8' : 'stroke-color-6')}
					>
						<path d="M7.55078 13.1011L1.55078 7.10107L7.55078 1.10107" strokeWidth="2" strokeLinecap="round" />
					</svg>
				</button>
			)
	}
}
