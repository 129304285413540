import { DependencyList, EffectCallback, useEffect } from 'react'
import useIsFirstRender from './useIsFirstRender'

/**
 * Just modified version of useEffect that is skipping the first render.
 * @see {@link https://usehooks-ts.com/react-hook/use-update-effect usehooks-ts.com}
 * @param effect — Imperative function that can return a cleanup function
 * @param deps — If present, effect will only activate if the values in the list change.
 * @version — 16.8.0
 */
const useUpdateEffect = (effect: EffectCallback, deps?: DependencyList): void => {
	const isFirst = useIsFirstRender()

	useEffect(() => {
		if (!isFirst) {
			return effect()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps)
}

export default useUpdateEffect
