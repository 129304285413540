import { FC } from 'react'

// Stroke
export const ShareIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} viewBox="0 0 54 66" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="none"
			d="M3.947 33.292v23.2a5.8 5.8 0 0 0 5.8 5.8h34.8a5.8 5.8 0 0 0 5.8-5.8v-23.2M38.746 15.893l-11.6-11.6-11.6 11.6M27.143 4.292v37.7"
			strokeWidth="6.8"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
