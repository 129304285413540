import { FC } from 'react'

export const BlinkingDots: FC = () => {
	return (
		<div className="flex flex-">
			<div className="blink">.</div>
			<div className="blink" style={{ animationDelay: '200ms' }}>
				.
			</div>
			<div className="blink" style={{ animationDelay: '400ms' }}>
				.
			</div>
		</div>
	)
}
