import { FC } from 'react'

// Stroke
export const NewWindowIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="none"
			d="M12.6667 8.27772V12.9444C12.6667 13.3569 12.5028 13.7526 12.2111 14.0443C11.9193 14.3361 11.5237 14.4999 11.1111 14.4999H2.55556C2.143 14.4999 1.74733 14.3361 1.45561 14.0443C1.16389 13.7526 1 13.3569 1 12.9444V4.38883C1 3.97627 1.16389 3.58061 1.45561 3.28889C1.74733 2.99716 2.143 2.83328 2.55556 2.83328H7.22222M10.3333 0.5H15M15 0.5V5.16667M15 0.5L6.44445 9.05556"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
