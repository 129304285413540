// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
	dsn: SENTRY_DSN || 'https://9f88a391cd8342f99a75f75e1a29615f@o1253392.ingest.sentry.io/6420343',
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.2,
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
	beforeSend: (event, hint) => {
		if (process.env.NODE_ENV === 'development') {
			console.error('Sentry caught event:', hint.originalException || hint.syntheticException || event)
			if (event.extra) {
				console.info('Extras:', event.extra)
			}
			return null
		}

		return event
	},
})
