import { useAppContext } from '@components/app-context'
import { Web3Provider } from '@ethersproject/providers'
import useSigninWithWallet from '@hooks/useSigninWithWallet'
import { WalletType } from 'lib/wallet'
import { FC } from 'react'
import { useQuery } from 'react-query'
import { ConnectHeader, ConnectWalletStepHeading, Retry } from '../ConnectComponents'

interface Props {
	onClose: () => void
}

const Metamask: FC<Props> = ({ onClose }) => {
	const { web3Modal } = useAppContext()

	const {
		mutate: signIn,
		isLoading: isSigninIn,
		isSuccess: isSignedIn,
	} = useSigninWithWallet({
		onSuccess: onClose,
	})

	const {
		data: provider,
		isSuccess: isConnected,
		isLoading: isConnecting,
	} = useQuery(['connect-wallet', WalletType.Metamask], () => web3Modal.connectTo(WalletType.Metamask), {
		staleTime: Infinity,
		cacheTime: 0,
		enabled: !!web3Modal,
		retry: false,
		onSuccess: (provider) => signIn(new Web3Provider(provider)),
	})

	return (
		<div className="flex flex-col items-center justify-center w-full">
			<ConnectHeader />
			<div className="border border-color-4 divide-y divide-color-4 w-full mt-8 rounded-2xl">
				<ConnectWalletStepHeading step="authorize" isLoading={isConnecting} isSuccess={!!isConnected} />
				<ConnectWalletStepHeading step="sign" isLoading={isSigninIn} isSuccess={isSignedIn} />
			</div>
			<div className="mt-8 self-end">
				{isConnected && provider && <Retry onClick={() => signIn(new Web3Provider(provider))} />}
			</div>
		</div>
	)
}

export default Metamask
