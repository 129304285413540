export default {
	defaultTitle: 'Coinvise',
	description: 'Powerful Tools For Creators To Build & Operate Tokenized Communities',
	openGraph: {
		type: 'website',
		locale: 'en_US',
		url: 'https://www.coinvise.co/',
		site_name: 'Coinvise',
	},
	twitter: {
		handle: '@CoinviseCo',
		site: '@CoinviseCo',
		cardType: 'summary_large_image',
	},
}
