import { FC } from 'react'

// Stroke
export const GnosisSafeIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.578.196c-1.78 0-3.52.531-5.001 1.527a9.056 9.056 0 0 0-3.317 4.07 9.138 9.138 0 0 0-.513 5.242 9.094 9.094 0 0 0 2.462 4.646 8.978 8.978 0 0 0 4.608 2.483c1.746.35 3.556.171 5.2-.515a9.016 9.016 0 0 0 4.04-3.341 9.122 9.122 0 0 0 1.517-5.04 9.109 9.109 0 0 0-2.635-6.413A8.965 8.965 0 0 0 9.578.196Zm6.587 9.567h-4.18a2.565 2.565 0 0 1-.991 1.566 2.528 2.528 0 0 1-3.415-.379 2.576 2.576 0 0 1 .076-3.462 2.53 2.53 0 0 1 3.428-.226c.496.401.825.975.922 1.609h4.156a.44.44 0 0 1 .443.446.44.44 0 0 1-.442.446h.003Z" />
		</svg>
	)
}
