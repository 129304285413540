import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { rpcList } from 'helpers/utils'
import { IProviderOptions } from 'web3modal'

type ValueOf<T> = T[keyof T]

export const walletconnectProvider = new WalletConnectProvider({
	rpc: rpcList,
	storageId: 'custom-walletconnect',
	qrcode: false,
})

const walletconnectMobileProvider = new WalletConnectProvider({
	rpc: rpcList,
	storageId: 'custom-walletconnect-mobile',
	qrcode: true,
})

export const walletConnectOptions = (provider: WalletConnectProvider): ValueOf<IProviderOptions> => ({
	display: {
		logo: '/images/Wallets/wallet-connect.svg',
		name: 'Wallet Connect',
		description: 'Connect to your wallet connect wallet',
	},
	options: {
		appName: 'Coinvise', // Your app name
		networkUrl: `https://mainnet.infura.io/v3/aa079d178e3c44bb8dd492c3f99bee77`,
		chainId: 1,
	},
	package: provider,
	connector: async (provider: WalletConnectProvider): Promise<WalletConnectProvider> => {
		await provider.enable()
		return provider
	},
})

export enum WalletType {
	WalletConnect = 'custom-walletconnect',
	WalletConnectMobile = 'custom-walletconnect-mobile',
	Metamask = 'injected',
	Coinbase = 'coinbasewallet',
}

type ProviderOptions = Record<WalletType, ValueOf<IProviderOptions>>

export const web3ModalProviderOptions: ProviderOptions = {
	[WalletType.Metamask]: {
		display: {},
		package: null,
	},
	[WalletType.WalletConnect]: walletConnectOptions(walletconnectProvider),
	[WalletType.WalletConnectMobile]: walletConnectOptions(walletconnectMobileProvider),
	[WalletType.Coinbase]: {
		options: {
			appName: 'Coinvise', // Your app name
			infuraId: 'aa079d178e3c44bb8dd492c3f99bee77',
			darkMode: true,
			rpc: rpcList,
		},
		package: CoinbaseWalletSDK,
	},
}
