import { FC } from 'react'
import clsx from 'clsx'

// Fill
export const BullseyeIcon: FC<{ className: string; gradient?: boolean }> = ({ className, gradient }) => (
	<svg
		className={clsx(className, gradient && 'fill-[url(#bullseye_gradient)]')}
		viewBox="0 0 31 31"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.5 28.406c6.904 0 12.5-5.596 12.5-12.5 0-6.903-5.596-12.5-12.5-12.5S3 9.003 3 15.906c0 6.904 5.596 12.5 12.5 12.5Zm0 2.5c8.284 0 15-6.715 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.5 22.415a6.51 6.51 0 1 0 0-13.018 6.51 6.51 0 0 0 0 13.018Zm0 2.5a9.01 9.01 0 1 0 0-18.018 9.01 9.01 0 0 0 0 18.018Z"
		/>
		<path d="M18.5 15.906a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
		<defs>
			<linearGradient
				id="bullseye_gradient"
				x1="31.201"
				y1="-3.76"
				x2="-1.482"
				y2="-1.972"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#E8B05D" />
				<stop offset="28%" stopColor="#ED6F4D" />
				<stop offset="81%" stopColor="#7765CB" />
				<stop offset="100%" stopColor="#4F81E2" />
			</linearGradient>
		</defs>
	</svg>
)
