import clsx from 'clsx'
import { FC } from 'react'

// Stroke
export const TokenIcon: FC<{ className: string; gradient?: boolean }> = ({ className, gradient }) => (
	<svg
		className={clsx(className, gradient && 'stroke-[url(#token_gradient)]')}
		width="31"
		height="31"
		viewBox="11 3 9 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="15.5" cy="15.6211" r="11.75" strokeWidth="1.5" />
		<circle cx="15.5" cy="15.6211" r="8" strokeWidth="1.5" strokeDasharray="3 3" />
		<defs>
			<linearGradient
				id="token_gradient"
				x1="28.5841"
				y1="-0.767784"
				x2="1.34845"
				y2="0.722837"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#E8B05D" />
				<stop offset="0.28125" stopColor="#ED6F4D" />
				<stop offset="0.807292" stopColor="#7765CB" />
				<stop offset="1" stopColor="#4F81E2" />
			</linearGradient>
		</defs>
	</svg>
)
