import { StaticToken } from '../interfaces/interfaces'

// TODO: add YUR and JUMP

const tokens: StaticToken[] = [
	{
		address: '0x9355372396e3f6daf13359b7b607a3374cc638e0',
		symbol: 'whale',
		name: 'WHALE',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/11797/large/WHALE.png?1595004706',
	},
	{
		address: '0xf552b656022c218c26dad43ad88881fc04116f76',
		symbol: 'mork',
		name: 'MORK',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/12183/large/mork-logo.png?1597941710',
	},
	{
		address: '0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198',
		symbol: 'bank',
		name: 'Bankless DAO',
		tokenSupply: '1000000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/15227/large/j4WEJrwU.png?1622615796',
	},
	{
		address: '0xfb5453340c03db5ade474b27e68b6a9c6b2823eb',
		symbol: 'robot',
		name: 'Robot',
		tokenSupply: '420000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/13517/large/MF_Robot_200px.png?1609312481',
	},
	{
		address: '0xe6710e0cda178f3d921f456902707b0d4c4a332b',
		symbol: 'julien',
		name: 'JULIEN',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/12954/large/julien_logo.jpg?1603792446',
	},
	{
		address: '0x35872fea6a4843facbcdbce99e3b69596a3680b8',
		symbol: '1337',
		name: '1337',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/13348/large/logo.png?1607791847',
	},
	{
		address: '0x35bd01fc9d6d5d81ca9e055db88dc49aa2c699a8',
		symbol: 'fwb',
		name: 'Friends With Benefits Pro',
		tokenSupply: '1000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/14391/large/xRGEXmQN_400x400.png?1615868085',
	},
	{
		address: '0xca3fe04c7ee111f0bbb02c328c699226acf9fd33',
		symbol: 'seen',
		name: 'SEEN',
		tokenSupply: '1000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/13052/large/seen_logo.png?1604678509',
	},
	{
		address: '0x6307b25a665efc992ec1c1bc403c38f3ddd7c661',
		symbol: 'gcr',
		name: 'Global Coin Research',
		tokenSupply: '4000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/14815/large/wun6fV4T_400x400.jpg?1618544214',
	},
	{
		address: '0xbcc66ed2ab491e9ae7bf8386541fb17421fa9d35',
		symbol: 'skull',
		name: 'Skull',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/10641/large/skull.png?1581339740',
	},
	{
		address: '0xc22b30e4cce6b78aaaadae91e44e73593929a3e9',
		symbol: 'rac',
		name: 'RAC',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/12675/large/rac_logo_%281%29.jpg?1601526417',
	},
	{
		address: '0x87b008e57f640d94ee44fd893f0323af933f9195',
		symbol: 'coin',
		name: 'Coin Artist',
		tokenSupply: '3470000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/12375/large/coin_artist_logo.png?1599403918',
	},
	{
		address: '0xee3b9b531f4c564c70e14b7b3bb7d516f33513ff',
		symbol: 'dfio',
		name: 'DeFi Omega',
		tokenSupply: '1000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/12191/large/defi_omega_logo.png?1597978243',
	},
	{
		address: '0x1287c0509df9a475ef178471ab2132b9dfd312b3',
		symbol: 'ladz',
		name: 'LADZ',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/13315/large/ladz_logo.jpg?1607408640',
	},
	{
		address: '0x56687cf29ac9751ce2a4e764680b6ad7e668942e',
		symbol: 'jamm',
		name: 'FlynnJamm',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/11943/large/jamm.png?1602491065',
	},
	{
		address: '0x8ba6dcc667d3ff64c1a2123ce72ff5f0199e5315',
		symbol: 'alex',
		name: 'Alex',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/10972/large/ALEX.png?1586742545',
	},
	{
		address: '0xdcfe18bc46f5a0cd0d3af0c2155d2bcb5ade2fc5',
		symbol: 'hue',
		name: 'Hue',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/10420/large/untitled.png?1579141360',
	},
	{
		address: '0x9903a4cd589da8e434f264deafc406836418578e',
		symbol: 'FIRST',
		name: 'Harrison First',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/12196/large/cc2016f6-0c74-4a95-b89b-b1684c7b9426.png?1597991823',
	},
	{
		address: '0x7e9d8f07a64e363e97a648904a89fb4cd5fb94cd',
		symbol: 'ff',
		name: 'Forefront',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/14659/large/N2kir6jx_400x400.jpg?1617608020',
	},
	{
		address: '0x7841b2a48d1f6e78acec359fed6d874eb8a0f63c',
		symbol: 'kerman',
		name: 'KERMAN',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/11536/large/Kerman.png?1590776066',
	},
	{
		address: '0xa19a40fbd7375431fab013a4b08f00871b9a2791',
		symbol: 'swagg',
		name: 'Swagg Network',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/12126/large/swagg_logo.png?1597376071',
	},
	{
		address: '0x39ad22c916f42af5f67371d6f2fb0dab42321a89',
		symbol: 'osina',
		name: 'OSINA',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/12977/large/OSINA_Logo.png?1604019955',
	},
	{
		address: '0x69bbc3f8787d573f1bbdd0a5f40c7ba0aee9bcc9',
		symbol: 'yup',
		name: 'Yup',
		tokenSupply: '9315081',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/12322/large/yupx.png?1599094638',
	},
	{
		address: '0x64d91f12ece7362f91a6f8e7940cd55f05060b92',
		symbol: 'ash',
		name: 'ASH',
		tokenSupply: '1904991',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/15714/large/omnPqaTY.png?1622820503',
	},
	{
		address: '0x537a9095b78517597b5f2058edcd6e1978095909',
		symbol: 'dsgn',
		name: 'Design',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/13204/large/design_logo.jpg?1606196569',
	},
	{
		address: '0x30cf203b48edaa42c3b4918e955fed26cd012a3f',
		symbol: 'seed',
		name: 'MetaGame',
		tokenSupply: '31476.556208458',
		network_chain_id: '1',
		image: 'https://assets.coingecko.com/coins/images/13099/large/V8phEz8V.png?1612854078',
	},
	{
		address: '0x69353f2d0e00fdaa38edfddfacbd15e8af5b846a',
		symbol: 'YUR',
		name: 'YUR DAO',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image:
			'https://cdn.discordapp.com/attachments/792847357557473300/875634890249469952/cropped-YUR-Final-Logo-512-192x192.png',
	},
	{
		address: '0x7Ce217B011aB060196128Ab86a27be7763802fBc',
		symbol: 'CROWD',
		name: 'The Crowd',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://res.cloudinary.com/coinvise/image/upload/v1623632140/ac5oh6evexbh1lcbkcx0.png',
		decimals: 18,
	},
	{
		address: '0xfec2fa7edd428171103e8f81911af5be9d31eeba',
		symbol: 'PRTN',
		name: 'Protein',
		tokenSupply: '100000000',
		network_chain_id: '1',
		decimals: 18,
		image: 'https://pbs.twimg.com/profile_images/1332239594218278912/adqUurIj_200x200.jpg',
	},
	{
		address: '0xc8703d17e542357b04441b2ce68a57fb1883064b',
		symbol: 'MECXXX',
		name: 'MECXXX',
		tokenSupply: '10000000',
		network_chain_id: '1',
		decimals: 18,
		image: 'https://res.cloudinary.com/coinvise/image/upload/v1630942831/fukqdewiy1qtsu7lhc95.png',
	},
	{
		address: '0x07e156da9f07cb59e220456a362c44c198b9e0fa',
		symbol: 'WTF',
		name: 'WTF',
		tokenSupply: '10000000',
		network_chain_id: '1',
		decimals: 18,
		image: 'https://res.cloudinary.com/coinvise/image/upload/v1623773981/ifdtehygbm4xgpcwukho.png',
	},
	{
		address: '0xB44a59d72cb84A2F29cD1836734208e1860798fD',
		symbol: 'CULTUR',
		name: 'The Culture DAO',
		tokenSupply: '10000000',
		network_chain_id: '1',
		image: 'https://res.cloudinary.com/coinvise/image/upload/v1632311603/cultur_logo_jqtvmu.png',
		decimals: 18,
	},
	{
		address: '0x6d9c990f0c9c3a072ae70c6c20b97ea66dfd8f7d',
		symbol: 'BIRRA',
		name: 'Birra',
		image: 'https://res.cloudinary.com/coinvise/image/upload/v1632311721/BIRRA-FLOC-LOGO_mlofhc.png',
		tokenSupply: '10000000',
		network_chain_id: '1',
		decimals: 18,
	},
	{
		address: '0x9eeab5dacad364017c400bfbf717543a0e3c35cb',
		symbol: 'MIGUEL',
		name: 'Miguel Piedrafita',
		image: 'https://etherscan.io/token/images/miguelpiedrafita_128.png',
		tokenSupply: '10000000',
		network_chain_id: '1',
		decimals: 18,
	},
	{
		address: '0x8313E886E9174dB7098Fb99B168499534548BB2B',
		symbol: 'Kenneth',
		name: 'Kenneth Schlenker',
		image: 'https://pbs.twimg.com/profile_images/1259116324397211648/RxHHku8u_400x400.jpg',
		tokenSupply: '10000000',
		network_chain_id: '1',
		decimals: 18,
	},
	{
		address: '0xc150bAc3Cd3678aCB2C093E433BED40a6eF08542',
		symbol: 'Shiny',
		name: 'SHINY',
		image: 'https://res.cloudinary.com/coinvise/image/upload/v1634726098/yuwubvroibkf0tu2uhgr.png',
		tokenSupply: '15000000',
		network_chain_id: '1',
		decimals: 18,
	},
	{
		address: '0xBAac2B4491727D78D2b78815144570b9f2Fe8899',
		symbol: 'The Doge NFT',
		name: 'DOG',
		image: 'https://etherscan.io/token/images/thedogenft_32.png',
		tokenSupply: '16969696969',
		network_chain_id: '1',
		decimals: 18,
	},
	{
		address: '0x57ea7178505f7d33c2bdb4450e041561513dbd9b',
		symbol: 'Citizen DAO Token',
		name: 'CDAO',
		tokenSupply: '100003',
		network_chain_id: '1',
		decimals: 18,
	},
]

export default tokens
