import { FC } from 'react'

// Fill
export const SpinnerIcon: FC<{ className }> = ({ className }) => (
	<svg className={className} width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			opacity=".25"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.5 5.914a8.586 8.586 0 1 0 0 17.172 8.586 8.586 0 0 0 0-17.172ZM.19 14.5C.19 6.597 6.598.19 14.5.19c7.903 0 14.31 6.407 14.31 14.31 0 7.903-6.407 14.31-14.31 14.31C6.597 28.81.19 22.402.19 14.5Z"
			fill="#FFFEFA"
		/>
		<path
			d="M7.12 10.111a8.581 8.581 0 0 1 7.38-4.196 8.581 8.581 0 0 1 7.38 4.196c2.44-1.012 4.007-2.516 4.066-4.2A14.288 14.288 0 0 0 14.5.191c-4.68 0-8.835 2.247-11.446 5.72.059 1.684 1.626 3.188 4.066 4.2Z"
			fill="#17BB5C"
		/>
	</svg>
)
