import { EthDefaultIcon, PolygonIcon } from '@components/icons'
import { isProduction } from './constants'

export class Chain {
	private chainId: number

	static readonly supportedTestnetChains = {
		ropsten: 3,
		rinkeby: 4,
		goerli: 5,
		polygonMumbai: 80_001,
	}

	static readonly supportedMainnetChains = {
		mainnet: 1,
		polygon: 137,
	}

	static readonly supportedChains = {
		...Chain.supportedMainnetChains,
		...Chain.supportedTestnetChains,
	}

	/**
	 * @param _chain The chain ID or name
	 */
	constructor(_chain: string | number) {
		const _chainIdNumber = Number(_chain)
		if (!isNaN(_chainIdNumber)) {
			this.chainId = _chainIdNumber
			return
		}

		const potentialChainId = Chain.supportedChains[_chain]
		if (potentialChainId) {
			this.chainId = potentialChainId
			return
		}

		this.chainId = 0
	}

	/**
	 * @param includeTestnets Whether to include testnets regardless of environment
	 * @returns array of all supported chain IDs
	 */
	static getChainNames(includeTestnets?: boolean): Array<string> {
		// Return only mainnet chains
		if (isProduction && !includeTestnets) return Object.keys(Chain.supportedMainnetChains)
		// Return all supported chains
		else return Object.keys(Chain.supportedChains)
	}

	getChainId(): number {
		return this.chainId
	}

	getChainIdHex(): string {
		return this.chainId.toString(16)
	}

	getChainName(): string {
		switch (this.chainId) {
			case 1:
				return 'Ethereum'

			case 3:
				return 'Ropsten'

			case 4:
				return 'Rinkeby'

			case 5:
				return 'Goerli'

			case 137:
				return 'Polygon'

			case 80001:
				return 'Polygon Mumbai'

			default:
				''
		}
	}

	isPolygon(): boolean {
		return [137, 80001].includes(this.chainId)
	}

	isEthereum(): boolean {
		return [1, 3, 4, 5].includes(this.chainId)
	}

	getNetworkIcon(): JSX.Element {
		if (this.isEthereum()) return <EthDefaultIcon className="w-4 h-4" />
		if (this.isPolygon()) return <PolygonIcon className="w-4 h-4 fill-[#8247E5]" />

		return null
	}

	getNetworkSymbol(): string {
		if (this.isEthereum()) return 'ETH'
		if (this.isPolygon()) return 'MATIC'

		return 'Unknown'
	}

	getBlockExplorerLink(): string {
		switch (this.chainId) {
			case 1:
				return 'https://etherscan.io'

			case 137:
				return 'https://polygonscan.com'

			case 80_001:
				return 'https://mumbai.polygonscan.com'

			case 5:
				return 'https://goerli.etherscan.io'

			case 4:
				return 'https://rinkeby.etherscan.io'

			case 3:
				return 'https://ropsten.etherscan.io'

			default:
				return ''
		}
	}

	getTransactionLink(txHash: string): string {
		return this.getBlockExplorerLink() + '/tx/' + txHash
	}
}

export type Keys = keyof typeof Chain.supportedChains
export type SupportedChain = typeof Chain.supportedChains[Keys]
