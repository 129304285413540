import { FC } from 'react'

// Stroke
export const SignOutIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="m17.496 7.78 2.824 3.389-2.824-3.39Z" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
		<path d="m17.496 14.558 2.824-3.39-2.824 3.39Z" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M18.625 11.169H9.814M1.68 1.68h12.2M1.68 20.658h12.2M13.88 1.68v5.422M13.88 15.236v5.422M1.68 1.68v18.979"
			strokeWidth="1.8"
			strokeLinecap="round"
		/>
	</svg>
)
