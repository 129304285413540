import clsx from 'clsx'
import { FC } from 'react'

// Fill
export const HexStatsIcon: FC<{ className?: string }> = ({ className }) => (
	<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.9631 1.00152L11.9796 1.00211L11.996 1.00216C12.4774 1.00351 12.9261 1.08314 13.3075 1.25012C14.6926 2.0186 16.0637 2.79754 17.4399 3.57937C18.2966 4.06605 19.1553 4.55386 20.0205 5.04095L20.0561 5.06103L20.0933 5.07813C21.2211 5.59662 22.0314 6.61338 22.1566 7.72539C22.1708 9.28484 22.1686 10.8373 22.1664 12.3959C22.1651 13.3673 22.1637 14.3411 22.1663 15.3204L22.1664 15.3626L22.1701 15.4046C22.2747 16.6042 21.7908 17.8019 20.8594 18.4719C19.4881 19.2649 18.1145 20.0406 16.7358 20.8192C15.879 21.303 15.0203 21.788 14.1589 22.2789L14.1232 22.2992L14.0893 22.3224C13.0702 23.0197 11.7533 23.2081 10.687 22.7484C9.3033 21.9808 7.93393 21.2025 6.55934 20.4212C5.70226 19.9341 4.84315 19.4458 3.9773 18.9583L3.94168 18.9382L3.90454 18.9211C2.77655 18.4022 1.96627 17.3856 1.84121 16.2737C1.82706 14.7145 1.82921 13.1622 1.83137 11.6038C1.83272 10.6322 1.83407 9.65818 1.83148 8.67866L1.83137 8.63769L1.82791 8.59687C1.73175 7.46222 2.14932 6.33637 2.98497 5.64454C4.06201 4.97453 5.1462 4.36931 6.25357 3.75116C6.93583 3.37031 7.62688 2.98455 8.33046 2.5757C8.68887 2.39223 9.03458 2.18626 9.35 1.99823L9.3561 1.99459C9.68658 1.79758 9.98557 1.61934 10.2873 1.4638C10.8893 1.15354 11.4143 0.981881 11.9631 1.00152Z"
			strokeWidth="2"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.63281 11.8906L8.63281 16.5853L6.38281 16.5853L6.38281 11.8906L8.63281 11.8906Z"
			stroke="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.125 6.26172L13.125 16.5882L10.875 16.5882L10.875 6.26172L13.125 6.26172Z"
			stroke="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.6172 9.53125L17.6172 16.5869L15.3672 16.5869L15.3672 9.53125L17.6172 9.53125Z"
			stroke="none"
		/>
	</svg>
)

// Fill
export const HexGlitterIcon: FC<{ className?: string }> = ({ className }) => (
	<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.9631 1.00152L11.9796 1.00211L11.996 1.00216C12.4774 1.00351 12.9261 1.08314 13.3075 1.25012C14.6926 2.01862 16.0638 2.79758 17.44 3.57943C18.2967 4.0661 19.1553 4.55388 20.0205 5.04095L20.0561 5.06103L20.0933 5.07813C21.2211 5.59662 22.0314 6.61338 22.1566 7.72539C22.1708 9.2849 22.1686 10.8374 22.1664 12.3961C22.1651 13.3674 22.1637 14.3412 22.1663 15.3204L22.1664 15.3626L22.1701 15.4046C22.2747 16.6042 21.7908 17.8019 20.8594 18.4719C19.4881 19.2648 18.1145 20.0406 16.7358 20.8192C15.879 21.303 15.0203 21.788 14.1589 22.2789L14.1232 22.2992L14.0893 22.3224C13.0702 23.0197 11.7533 23.2081 10.687 22.7484C9.30331 21.9808 7.93393 21.2025 6.55934 20.4212C5.70226 19.9341 4.84315 19.4458 3.9773 18.9583L3.94168 18.9382L3.90454 18.9211C2.77655 18.4022 1.96627 17.3856 1.84121 16.2737C1.82706 14.7145 1.82921 13.1622 1.83137 11.6038C1.83272 10.6322 1.83407 9.65818 1.83148 8.67866L1.83137 8.63769L1.82791 8.59687C1.73175 7.46222 2.14932 6.33638 2.98496 5.64454C4.06201 4.97453 5.14621 4.36931 6.25357 3.75116C6.93583 3.3703 7.62688 2.98455 8.33046 2.5757C8.68887 2.39223 9.03458 2.18626 9.35 1.99823L9.35612 1.99458C9.68659 1.79757 9.98557 1.61933 10.2873 1.4638C10.8893 1.15354 11.4143 0.981881 11.9631 1.00152Z"
			strokeWidth="2"
			fill="none"
		/>
		<path
			d="M17.9838 12.0007C12.9809 12.5378 12.5358 12.9828 11.9987 17.9857C11.4616 12.9828 11.0319 12.5378 6.01367 12.0007C11.0319 11.4636 11.4616 11.0185 11.9987 6.01562C12.5358 11.0185 12.9809 11.4636 17.9838 12.0007Z"
			stroke="none"
		/>
	</svg>
)

// Fill
export const HexNftIcon: FC<{ className?: string; gradient?: boolean }> = ({ className, gradient }) => (
	<svg
		className={clsx(className, gradient && 'fill-[url(#hexnfticon_gradient)]')}
		width="22"
		height="24"
		viewBox="0 0 22 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.5002 2.53192L18.8925 6.79987C19.2019 6.97851 19.3925 7.30863 19.3925 7.6659V16.2018C19.3925 16.5591 19.2019 16.8892 18.8925 17.0678L11.5002 21.3358C11.1908 21.5144 10.8096 21.5144 10.5002 21.3358L3.10791 17.0678C2.79851 16.8892 2.60791 16.5591 2.60791 16.2018V7.6659C2.60791 7.30863 2.79851 6.97851 3.10791 6.79987L10.5002 2.53192C10.8096 2.35329 11.1908 2.35329 11.5002 2.53192ZM9.50021 0.799873C10.4284 0.263975 11.572 0.263975 12.5002 0.799873L19.8925 5.06782C20.8207 5.60372 21.3925 6.5941 21.3925 7.6659V16.2018C21.3925 17.2736 20.8207 18.264 19.8925 18.7999L12.5002 23.0678C11.572 23.6037 10.4284 23.6037 9.50021 23.0678L2.10791 18.7999C1.17971 18.264 0.60791 17.2736 0.60791 16.2018V7.6659C0.60791 6.5941 1.17971 5.60372 2.10791 5.06782L9.50021 0.799873ZM5.35617 9.85719H6.30534L7.97232 12.6038V9.85719H8.86216V14.0098H7.91299L6.24602 11.2631V14.0098H5.35617V9.85719ZM9.92822 9.85719H12.752V10.6225H10.8181V11.5479H12.3605V12.3191H10.8181V14.0098H9.92822V9.85719ZM13.2989 10.6225H14.5269V14.0098H15.4168V10.6225H16.6447V9.85719H13.2989V10.6225Z"
		/>
		<defs>
			<linearGradient
				id="hexnfticon_gradient"
				x1="21.8781"
				y1="-3.19099"
				x2="-0.777913"
				y2="-2.07393"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#E8B05D" />
				<stop offset="0.28125" stopColor="#ED6F4D" />
				<stop offset="0.807292" stopColor="#7765CB" />
				<stop offset="1" stopColor="#4F81E2" />
			</linearGradient>
		</defs>
	</svg>
)
