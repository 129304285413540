import { FC } from 'react'

// Fill
export const CautionIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.70751 0.39238C8.43073 -0.130793 7.56997 -0.130793 7.29318 0.39238L0.0935435 13.9917C0.0287335 14.1136 -0.00339642 14.2502 0.000284138 14.3882C0.00396469 14.5262 0.0433302 14.6609 0.114545 14.7791C0.18576 14.8974 0.286396 14.9952 0.406646 15.063C0.526896 15.1308 0.66266 15.1663 0.800708 15.166H15.2C15.3379 15.1663 15.4736 15.1309 15.5938 15.0631C15.714 14.9953 15.8145 14.8976 15.8857 14.7794C15.9568 14.6612 15.9961 14.5266 15.9997 14.3887C16.0034 14.2508 15.9712 14.1143 15.9064 13.9925L8.70751 0.39238ZM8.80031 11.9662C8.80031 12.408 8.44215 12.7662 8.00035 12.7662C7.55854 12.7662 7.20039 12.408 7.20039 11.9662C7.20039 11.5244 7.55854 11.1662 8.00035 11.1662C8.44215 11.1662 8.80031 11.5244 8.80031 11.9662ZM8.00067 9.56632C7.55869 9.56632 7.20039 9.20802 7.20039 8.76604V6.36656C7.20039 5.92471 7.55858 5.56652 8.00043 5.56652C8.44221 5.56652 8.80038 5.92461 8.80047 6.3664L8.80095 8.76588C8.80104 9.20792 8.44271 9.56632 8.00067 9.56632Z" />
		</svg>
	)
}
