import { Dispatch, SetStateAction, useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { getMilliFromMinutes } from 'utils/rq-settings'

type SetValue<T> = Dispatch<SetStateAction<T | null>>

const useLocalStorage = <T>(key: string, initialValue: T): readonly [T, SetValue<T>, () => void] => {
	// Get from local storage then
	// parse stored json or return initialValue
	const readValue = useCallback((): T => {
		// Prevent build error "window is undefined" but keep working
		if (typeof window === 'undefined') {
			return initialValue
		}

		try {
			const item = window.localStorage.getItem(key)
			return item ? (parseJSON(item) as T) : initialValue
		} catch (error) {
			console.warn(`Error reading localStorage key “${key}”:`, error)
			return initialValue
		}
	}, [initialValue, key])

	const query = useQuery<T>(['local-storage', key], readValue, {
		placeholderData: initialValue,

		// don't need network to fetcg
		refetchOnReconnect: false,
		refetchOnMount: 'always',

		staleTime: getMilliFromMinutes(10),
		cacheTime: getMilliFromMinutes(5),
	})

	const queryClient = useQueryClient()

	const setValue: SetValue<T> = (value) => {
		// Prevent build error "window is undefined" but keeps working
		if (typeof window == 'undefined') {
			console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`)
		}

		try {
			// Allow value to be a function so we have the same API as useState
			const newValue = value instanceof Function ? value(query.data) : value

			if (newValue == null) {
				window.localStorage.removeItem(key)
			} else {
				// Save to local storage
				window.localStorage.setItem(key, JSON.stringify(newValue))
			}

			// Save state
			queryClient.setQueryData(['local-storage', key], newValue)
			// setStoredValue(newValue)

			// We dispatch a custom event so every useLocalStorage hook are notified
			// window.dispatchEvent(new Event('local-storage'))
		} catch (error) {
			console.warn(`Error setting localStorage key “${key}”:`, error)
		}
	}

	const removeValue = (): void => setValue(null)

	return [query.data, setValue, removeValue] as const
}

export default useLocalStorage

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
	try {
		return value === 'undefined' ? undefined : JSON.parse(value ?? '')
	} catch {
		console.log('parsing error on', { value })
		return undefined
	}
}
