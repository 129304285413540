import { FC } from 'react'

// Stroke
export const ConnectGnosisSafeIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill="none"
				d="M5.073 15.263h8.229l4.114-6.995-4.114-6.995h-8.23"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fill="none"
				d="m9.414 4.879 2.824 3.389-2.824-3.39ZM9.414 11.657l2.824-3.39-2.824 3.39ZM10.544 8.268H1.732"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
