import { FC } from 'react'

// Fill
export const WalletIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 63 57" xmlns="http://www.w3.org/2000/svg">
			<path d="M56.1386 0.148438H9.35643C4.19792 0.148438 0 4.34635 0 9.50486V46.9306C0 52.0891 4.19792 56.287 9.35643 56.287H56.1386C59.5786 56.287 62.3762 53.4894 62.3762 50.0494V6.38606C62.3762 2.94601 59.5786 0.148438 56.1386 0.148438ZM9.35643 50.0494C7.63485 50.0494 6.23762 48.649 6.23762 46.9306V9.50486C6.23762 7.7864 7.63485 6.38606 9.35643 6.38606H56.1386V15.7425H37.4257C33.9857 15.7425 31.1881 18.5401 31.1881 21.9801V34.4553C31.1881 37.8954 33.9857 40.693 37.4257 40.693H56.1417V50.0494H9.35643ZM56.1386 21.9801V34.4553H37.4257V21.9801H56.1386Z" />
			<defs>
				<linearGradient
					id="wallet_orangegradient"
					x1="0"
					y1="0.148438"
					x2="55.8284"
					y2="62.18"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.239583" stopColor="#FF9E75" />
					<stop offset="1" stopColor="#BB4E75" />
				</linearGradient>
			</defs>
		</svg>
	)
}
