import { FC } from 'react'

// Fill
export const EthIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.354 0 0 5.634l3.354 2.012 3.353-2.012L3.354 0ZM0 6.305 3.354 11l3.353-4.695-3.353 2.012L0 6.305Z" />
	</svg>
)

// Fill
export const EthEnsIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.46278 12.5644C3.84743 13.231 4.80932 13.2314 5.19459 12.5652L8.37573 7.06434C8.55483 6.75462 8.55483 6.37282 8.37573 6.06311L5.19459 0.562243C4.80932 -0.103958 3.84743 -0.103513 3.46278 0.563044L0.288426 6.06391C0.109936 6.37321 0.109936 6.75423 0.288426 7.06354L3.46278 12.5644Z" />
	</svg>
)

// Default colors
export const EthDefaultIcon: FC<{ className: string }> = ({ className }) => (
	<svg
		className={className}
		width="328"
		height="534"
		viewBox="0 0 328 534"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M160.375 365.138L163.951 368.707L327.648 271.946L163.951 0.333344L160.375 12.4863V365.138Z"
			fill="#343434"
		/>
		<path d="M163.952 368.708V0.333344L0.255127 271.946L163.952 368.708Z" fill="#8C8C8C" />
		<path
			d="M161.936 527.781L163.951 533.665L327.746 302.99L163.952 399.702L161.937 402.158L161.936 527.781Z"
			fill="#3C3C3B"
		/>
		<path d="M0.255127 302.988L163.952 533.664V399.7L0.255127 302.988Z" fill="#8C8C8C" />
		<path d="M163.951 197.543V368.707L327.645 271.948L163.951 197.543Z" fill="#141414" />
		<path d="M163.949 197.543L0.255127 271.946L163.949 368.706V197.543Z" fill="#393939" />
	</svg>
)
