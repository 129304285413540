import clsx from 'clsx'
import Image from 'next/image'
import { FC } from 'react'

interface CheckboxProps {
	isChecked: boolean
	toggleCheckbox: () => void
	size?: 'sm' | 'md'
	disclaimer?: boolean
}

export const Checkbox: FC<CheckboxProps> = ({ isChecked, toggleCheckbox, size = 'md', disclaimer }) => {
	const sizeStyle = size === 'sm' ? 'w-5 h-5' : 'w-6 h-6'
	const disclaimerStyle = isChecked ? 'border-yes text-yes' : 'border-no text-no'

	return (
		<button
			type="button"
			onClick={toggleCheckbox}
			className={clsx(
				sizeStyle,
				disclaimer && disclaimerStyle,
				'shrink-0 border-color-3 rounded-md border bg-transparent flex justify-center items-center'
			)}
		>
			{isChecked && <Image src="/images/check.svg" width={11} height={11}></Image>}
		</button>
	)
}
