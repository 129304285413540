import { Web3Provider } from '@ethersproject/providers'
import backend from 'apiUtils/backend'
import axios from 'axios'
import { useCallback } from 'react'
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query'
import { SiweMessage } from 'siwe'

const useSigninWithWallet = (
	options?: Omit<UseMutationOptions<void, unknown, Web3Provider, unknown>, 'mutationFn'>
): UseMutationResult<void> => {
	const queryClient = useQueryClient()

	const signIn = useCallback(async (web3Provider: Web3Provider) => {
		if (!web3Provider) {
			console.log('NO web3 provider passed')
			return
		}

		const signer = web3Provider.getSigner()
		const chainId = await signer.getChainId()
		const address = await signer.getAddress()
		const nonce = await backend.get(`/user/getnonce?publicAddress=${address}`).then((res) => res.data?.nonce as string)

		const message = new SiweMessage({
			domain: window.location.host,
			address,
			statement: 'Sign in with Ethereum to Coinvise',
			uri: window.location.origin,
			version: '1',
			chainId,
			nonce,
		})

		window.localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE')
		const signature = await signer.signMessage(message.prepareMessage())

		await axios.post('/api/login/signature', {
			signature,
			message,
		})
	}, [])

	return useMutation(signIn, {
		...options,
		onSuccess: async (...args) => {
			await queryClient.refetchQueries('current_user')
			await queryClient.refetchQueries('current_profile')
			await queryClient.refetchQueries('balance')

			if (options.onSuccess) {
				await options.onSuccess(...args)
			}
		},
	})
}

export default useSigninWithWallet
