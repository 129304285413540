import { FC } from 'react'

// Fill
export const SearchIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.73093 15.8945C9.43874 15.8941 11.0973 15.3224 12.4426 14.2704L16.6722 18.5L18.0327 17.1395L13.8031 12.9099C14.8557 11.5645 15.4278 9.90549 15.4282 8.19724C15.4282 3.95318 11.975 0.5 7.73093 0.5C3.48687 0.5 0.0336914 3.95318 0.0336914 8.19724C0.0336914 12.4413 3.48687 15.8945 7.73093 15.8945ZM7.73093 2.42431C10.9147 2.42431 13.5039 5.01347 13.5039 8.19724C13.5039 11.381 10.9147 13.9702 7.73093 13.9702C4.54716 13.9702 1.958 11.381 1.958 8.19724C1.958 5.01347 4.54716 2.42431 7.73093 2.42431Z" />
		</svg>
	)
}
