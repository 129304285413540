import { FC } from 'react'

// Stroke
export const PencilIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.875 2.80859L8.3125 1.24609L2.0625 7.49609L1.4375 9.68359L3.625 9.05859L9.875 2.80859ZM7.0625 2.49609L8.625 4.05859L7.0625 2.49609ZM2.0625 7.49609L3.625 9.05859L2.0625 7.49609Z"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
