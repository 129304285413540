import { DownCaretIcon, UpCaretIcon } from '@components/icons'
import useUpdateEffect from '@hooks/useUpdateEffect'
import clsx from 'clsx'
import { forwardRef, KeyboardEvent, useImperativeHandle, useRef, useState } from 'react'

const spacing = `flex items-center px-3 sm:px-10 w-full min-h-12 h-[55px]`
interface CollapsibleProps {
	/**
	 * The label to display
	 */
	headerLabel: string
	/**
	 * The shortened label to display in mobile view
	 */
	secondaryHeaderLabel?: string
	/**
	 * The list of row items to render when opened
	 */
	list: JSX.Element[] | string[] | null
}

export interface CollapseFunction {
	setCollapsed: (val: boolean) => void
}

export const Collapsible = forwardRef<CollapseFunction, CollapsibleProps>(
	({ headerLabel, secondaryHeaderLabel, list }, ref) => {
		// Allows for parent component to call collapse if needed
		useImperativeHandle(ref, () => ({
			setCollapsed: (val) => {
				setCollapsed(val)
			},
		}))

		const scrollRef = useRef<HTMLDivElement>()
		const [collapsed, setCollapsed] = useState(true)

		useUpdateEffect(() => {
			if (scrollRef?.current) {
				scrollRef.current.scrollIntoView({
					block: 'center',
					behavior: 'smooth',
				})
			}
		}, [collapsed])

		const toggleCollapsed = (): void => {
			setCollapsed((collapsed) => !collapsed)
		}

		// User can Tab to focus and press space to toggle collapsible
		const onKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
			if (e.key === ' ' || e.key === 'Enter') {
				// Prevents accidental collapsing when toggling buttons within the component
				// i.e. toggling 'See All' button for a Whitelisted Addresses condition
				if (e.target === e.currentTarget) {
					e.preventDefault()
					toggleCollapsed()
				}
			}
		}

		return (
			<div
				ref={scrollRef}
				onKeyDown={onKeyDown}
				tabIndex={0}
				className="flex flex-col w-full mt-2 border border-color-3 rounded-xl"
			>
				<span
					onClick={toggleCollapsed}
					className={clsx(
						collapsed && 'border-b-0',
						!collapsed && 'text-color-8',
						spacing,
						'cursor-pointer font-semibold text-xs'
					)}
				>
					<span className="hidden sm:flex">{headerLabel}</span>
					<span className="flex sm:hidden">{secondaryHeaderLabel ?? headerLabel}</span>
					<span className="ml-auto">
						{collapsed ? <DownCaretIcon className="stroke-color-6" /> : <UpCaretIcon className="stroke-color-6" />}
					</span>
				</span>
				{!collapsed &&
					list?.map((item, i) => {
						return (
							<span key={i} className={clsx(spacing, 'items-center border-color-3 border-t')}>
								{item}
							</span>
						)
					})}
			</div>
		)
	}
)

Collapsible.displayName = 'Collapsible'
