import { isAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcProvider, WebSocketProvider } from '@ethersproject/providers'
import { Contract, Signer } from 'ethers'
import ERC20ABI from '../contracts/abis/ERC20TokenABI.json'
import { ERC20TokenABI } from '../contracts/abis/types'
import { rpcList, WebSocketsRPCList } from '../helpers/utils'

export enum GetContractTypeEnum {
	ReadOnlyWebsocket = 'wsProvider',
	ReadOnly = 'jsonRpcProvider',
	ReadAndWrite = 'signer',
}

interface OptionWithProvider {
	type: GetContractTypeEnum.ReadOnly | GetContractTypeEnum.ReadOnlyWebsocket
	chainId: number
}

interface OptionWithSigner {
	type: GetContractTypeEnum.ReadAndWrite
	signer: Signer
}

type Options = OptionWithProvider | OptionWithSigner

const getContract = <T extends Contract = Contract>(address?: string, ABI?: any, options?: Options): T => {
	if (!address || !ABI || !options) {
		throw new Error('Missing required arguments')
	}

	if (!isAddress(address) || address === AddressZero) {
		throw new Error(`Address ${address} is not valid`)
	}

	let signerOrProvider: Signer | WebSocketProvider | JsonRpcProvider

	switch (options.type) {
		case GetContractTypeEnum.ReadOnlyWebsocket: {
			const rpc = WebSocketsRPCList[options.chainId]
			if (!rpc) throw new Error(`No websocket provider found for chainId ${options.chainId}`)

			signerOrProvider = new WebSocketProvider(rpc)
			break
		}
		case GetContractTypeEnum.ReadOnly: {
			const rpc = rpcList[options.chainId]
			if (!rpc) throw new Error(`No json rpc provider found for chainId ${options.chainId}`)

			signerOrProvider = new JsonRpcProvider(rpc)
			break
		}

		case GetContractTypeEnum.ReadAndWrite:
			signerOrProvider = options.signer
			break

		default:
			throw new Error('Invalid option type')
	}

	return new Contract(address, ABI, signerOrProvider) as T
}

export const getTokenContract = (address?: string, options?: Options): ERC20TokenABI =>
	getContract<ERC20TokenABI>(address, ERC20ABI, options)

export default getContract
