import ProgressBar from '@badrap/bar-of-progress'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import AppProvider from '@components/contexts/AppProvider'
import { ConnectWalletProvider } from '@components/contexts/ConnectWalletProvider'
import { DefaultSeo } from 'next-seo'
import { ThemeProvider } from 'next-themes'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Hydrate } from 'react-query/hydration'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import CheckUser from '../components/CheckUser'
// import { accentColor } from '../helpers/constants'
import * as gtag from '../lib/gtag'
import SEO from '../next-seo.config'
import '../styles/index.scss'

const theme = extendTheme({
	fonts: {
		heading: 'Roobert, sans-serif',
		body: 'Roobert, sans-serif',
	},
})

const MyApp: FC<AppProps> = ({ Component, pageProps }): JSX.Element => {
	const [queryClient] = useState(() => new QueryClient())
	const [progress] = useState(
		() =>
			new ProgressBar({
				size: 3,
				// color: accentColor, // TODO: Change color
				className: 'bar-of-progress',
				delay: 100,
			})
	)

	const router = useRouter()

	useEffect(() => {
		router.events.on('routeChangeStart', progress.start)
		router.events.on('routeChangeComplete', progress.finish)
		router.events.on('routeChangeComplete', (url) => gtag.pageview(url))
		router.events.on('routeChangeError', progress.finish)

		return () => {
			router.events.off('routeChangeStart', progress.start)
			router.events.off('routeChangeComplete', progress.finish)
			router.events.off('routeChangeComplete', (url) => gtag.pageview(url))
			router.events.off('routeChangeError', progress.finish)
		}
	}, [router, progress])

	return (
		<QueryClientProvider client={queryClient}>
			<Hydrate state={pageProps.dehydratedState}>
				<ChakraProvider theme={theme}>
					<DefaultSeo {...SEO} />
					<Head>
						<link rel="shortcut icon" href="/favicon.ico" />
					</Head>
					<ConnectWalletProvider>
						<AppProvider>
							<ThemeProvider attribute="class" enableSystem={false} defaultTheme="dark">
								<ToastContainer position="top-left" autoClose={8000} newestOnTop pauseOnHover />
								<CheckUser />
								<div id="token-modal" />
								<div id="bottom-nav" />
								<div id="bridge-modal" />
								<div id="add-email-modal" />
								<div id="consent-modal" />
								<Component {...pageProps} />
							</ThemeProvider>
						</AppProvider>
					</ConnectWalletProvider>
				</ChakraProvider>
			</Hydrate>
			<ReactQueryDevtools position="top-right" initialIsOpen={false} />
		</QueryClientProvider>
	)
}

export default MyApp
