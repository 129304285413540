import { FC } from 'react'

// fill
export const CopyIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 1V15H13V5.49995H10.5C9.39543 5.49995 8.5 4.60452 8.5 3.49995V1H3ZM9.5 1V3.49995C9.5 4.05224 9.94772 4.49995 10.5 4.49995H13L9.50005 1H9.5ZM10 0L14 4V15C14 15.5523 13.5523 16 13 16H12V17C12 17.5523 11.5523 18 11 18H1C0.447715 18 0 17.5523 0 17V3C0 2.44772 0.447715 2 1 2H2V1C2 0.447715 2.44772 0 3 0H10ZM11 17V16H3C2.44772 16 2 15.5523 2 15V3H1L1 17H11Z"
		/>
	</svg>
)
