import { FC } from 'react'

// fill
export const ImportIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 15C0 14.7348 0.104565 14.4804 0.290691 14.2929C0.476817 14.1053 0.729259 14 0.992481 14H12.9023C13.1655 14 13.4179 14.1053 13.604 14.2929C13.7902 14.4804 13.8947 14.7348 13.8947 15C13.8947 15.2652 13.7902 15.5196 13.604 15.7071C13.4179 15.8946 13.1655 16 12.9023 16H0.992481C0.729259 16 0.476817 15.8946 0.290691 15.7071C0.104565 15.5196 0 15.2652 0 15ZM3.26824 4.70685C3.08218 4.51932 2.97765 4.26501 2.97765 3.99984C2.97765 3.73467 3.08218 3.48036 3.26824 3.29283L6.24568 0.29279C6.4318 0.105317 6.6842 0 6.94737 0C7.21054 0 7.46293 0.105317 7.64905 0.29279L10.6265 3.29283C10.8073 3.48144 10.9073 3.73404 10.9051 3.99624C10.9028 4.25844 10.7984 4.50926 10.6144 4.69467C10.4304 4.88008 10.1815 4.98525 9.92124 4.98753C9.66101 4.98981 9.41031 4.88901 9.22313 4.70685L7.93985 3.41383V10.9999C7.93985 11.2652 7.83528 11.5195 7.64916 11.707C7.46303 11.8946 7.21059 11.9999 6.94737 11.9999C6.68414 11.9999 6.4317 11.8946 6.24558 11.707C6.05945 11.5195 5.95489 11.2652 5.95489 10.9999V3.41383L4.67161 4.70685C4.48549 4.89432 4.23309 4.99964 3.96992 4.99964C3.70675 4.99964 3.45436 4.89432 3.26824 4.70685Z"
		/>
	</svg>
)
