import { FC } from 'react'

// Fill
export const CrossIcon: FC<{ className }> = ({ className }) => (
	<svg className={className} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.3488 5.21548C19.1298 4.43443 19.1298 3.1681 18.3488 2.38706C17.5677 1.60601 16.3014 1.60601 15.5204 2.38706L18.3488 5.21548ZM3.05915 14.8483C2.2781 15.6293 2.2781 16.8956 3.05915 17.6767C3.8402 18.4577 5.10653 18.4577 5.88758 17.6767L3.05915 14.8483ZM15.5204 2.38706L3.05915 14.8483L5.88758 17.6767L18.3488 5.21548L15.5204 2.38706Z" />
		<path d="M15.5204 17.6767C16.3014 18.4577 17.5677 18.4577 18.3488 17.6767C19.1298 16.8956 19.1298 15.6293 18.3488 14.8482L15.5204 17.6767ZM5.88758 2.38703C5.10653 1.60598 3.8402 1.60598 3.05915 2.38703C2.2781 3.16808 2.2781 4.43441 3.05915 5.21546L5.88758 2.38703ZM18.3488 14.8482L5.88758 2.38703L3.05915 5.21546L15.5204 17.6767L18.3488 14.8482Z" />
	</svg>
)

// Fill
export const CrossCircleIcon: FC<{ className }> = ({ className }) => (
	<svg className={className} width="11" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.01953 0.621094C2.25811 0.621094 0.0195312 2.85967 0.0195312 5.62109C0.0195313 8.38252 2.25811 10.6211 5.01953 10.6211C7.78095 10.6211 10.0195 8.38252 10.0195 5.62109C10.0195 2.85967 7.78095 0.621094 5.01953 0.621094ZM3.16598 3.76754C3.36124 3.57228 3.67782 3.57228 3.87308 3.76754L5.01953 4.91399L6.1659 3.76762C6.36116 3.57235 6.67775 3.57235 6.87301 3.76762C7.06827 3.96288 7.06827 4.27946 6.87301 4.47472L5.72664 5.62109L6.87273 6.76719C7.068 6.96245 7.068 7.27903 6.87273 7.4743C6.67747 7.66956 6.36089 7.66956 6.16563 7.4743L5.01953 6.3282L3.87336 7.47437C3.6781 7.66963 3.36152 7.66963 3.16625 7.47437C2.97099 7.27911 2.97099 6.96253 3.16625 6.76727L4.31242 5.62109L3.16598 4.47465C2.97072 4.27938 2.97072 3.9628 3.16598 3.76754Z"
		/>
	</svg>
)
