import { FC } from 'react'

// Fill
export const CheckCircleIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5Z" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.884 6.85402C12.2011 7.2002 12.1775 7.73787 11.8313 8.05494L7.43872 12.078L5.11983 9.57765C4.80062 9.23345 4.82087 8.69564 5.16508 8.37642C5.50928 8.0572 6.04709 8.07746 6.36631 8.42166L7.53638 9.68333L10.6831 6.8013C11.0293 6.48423 11.5669 6.50783 11.884 6.85402Z"
			fill="#E4E4ED"
		/>
	</svg>
)

// Fill
export const CheckIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.88352 0.854029C8.20059 1.20021 8.17698 1.73789 7.8308 2.05495L3.43823 6.07806L1.11935 3.57766C0.800129 3.23346 0.820384 2.69565 1.16459 2.37643C1.50879 2.05721 2.0466 2.07747 2.36582 2.42167L3.53589 3.68334L6.6826 0.801306C7.02878 0.484239 7.56645 0.507844 7.88352 0.854029Z"
		/>
	</svg>
)
